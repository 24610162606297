import React from "react";

function ChatBotCheckBox({
  question,
  setOptions,
  option,
  onClick,
  data,
  inputBox = null,
}) {
  return (
    <div className="flex flex-col">
      <p>{question}</p>
      {data.map((item, index) => {
        return (
          <div key={item.name} className="flex flex-row mb-2">
            <input
              onChange={(e) => {
                // add to list
                if (e.target.checked) {
                  setOptions([...option, item.label]);
                } else {
                  // remove from list
                  setOptions(option.filter((o) => o !== item.label));
                }
              }}
              value={option}
              className="mx-2"
              type="checkbox"
            />
            <p>{item.label}</p>
          </div>
        );
      })}
      <div className="mb-2">{inputBox && inputBox}</div>
      <div className="flex flex-col gap-2">
        <button
          aria-label="Next"
          className={"bg-sand px-2 py-2 rounded-md"}
          type="submit"
          onClick={onClick}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ChatBotCheckBox;
