export const loginImage = "";
export const logo4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/logo4.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827222239";
export const illustrationContactUsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/illustration_contact_us.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219187";
export const botImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/bot.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219382";
export const bot3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/bot2.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1678190131110";
export const logo2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/logo2.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827221997";
export const aboutUsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/about_us.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219392";
export const contactUsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/contact_us.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219273";
export const bgBody2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/bgbody2.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677828069314";
export const inquireUsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/inquire_us.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219657";
export const digitalImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/digital.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219300";
export const notFoundImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/404_1_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677826705897";
export const forgotPasswordBgImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/forgotpasswordbg.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1677828069576";
export const loginBgImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/loginbg.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1677828069407";
export const logo3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/logo3.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827222171";
export const phoneLoginBgImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/phoneloginbg1.jpeg?ik-sdk-version=javascript-1.4.3&updatedAt=1677828069529";
export const reactIconImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/reacticon.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219278";
export const developmentSkillsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/developmentskills.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677827219639";
export const userImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/user.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677827222132";
export const noItemsSelected = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/noitems_selected.png?updatedAt=1678968547903";

export const UrlEndPoint = "https://ik.imagekit.io/0hhctrxcv/";
export const oneImage = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/1.png?updatedAt=1678734629323";
export const twoImage = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/2.png?updatedAt=1678734629313";
export const threeImage = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/3.png?updatedAt=1678734629304";
export const fiveImage = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/5.png?updatedAt=1678734629222";
export const sixImage = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/6.png?updatedAt=1678734629254";

//Web App Development Images
export const wadBenefitsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wadbenefits.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677923008042";
export const wadCostEffectiveImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/cost-effective-subMain.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677925215822";
export const wadScalabilityImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/scalability.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677924806731";
export const wadAccessibilityImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/accessibility.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677925650613";
export const wadCrossPlatformImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/cross-platform.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677925982667";
export const wadDevelopmentSkillsImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/technicalSkillwad.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1677931232434";
export const notFoundTextImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/Page_Not_Found.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1678217425063";

//WAD Icons
export const wad1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/Wad1.svg?updatedAt=1678631154644";
export const wad2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad2.svg?updatedAt=1678631154658";
export const wad2_1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad2.1.svg?updatedAt=1678631154593";
export const wad3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad3.svg?updatedAt=1678631154643";
export const wad4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad4.svg?updatedAt=1678631154667";
export const wad5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad5.png?updatedAt=1678631154641";
export const wad6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad6.svg?updatedAt=1678631154642";
export const wad7Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad7.svg?updatedAt=1678631154511";
export const wad8Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WebAppDevelopment/wad8.svg?updatedAt=1678631154600";

//WAM Icons
export const wam1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/Wam1.png?updatedAt=1678674146006";
export const wam2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam2.svg?updatedAt=1678674092809";
export const wam3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam3.svg?updatedAt=1678674092838";
export const wam4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam4.png?updatedAt=1678674145967";
export const wam5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam5.png?updatedAt=1678674145932";
export const wam6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam6.png?updatedAt=1678674145969";
export const wam7Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam7.png?updatedAt=1678674145882";
export const wam8Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam8.png?updatedAt=1678674145960";
export const wam9Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam9.png?updatedAt=1678674145975";
export const wam10Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/wam10.png?updatedAt=1678674145936";
export const wamMainImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/WamMain.png?updatedAt=1678722644264";
export const wamb1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/wamB1.png?updatedAt=1678722644245";
export const wamb2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/wamB2.png?updatedAt=1678722644196";
export const wamb3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/wamB3.png?updatedAt=1678722644208";
export const wamb4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/wamB4.png?updatedAt=1678722644156";
export const wamb5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/wamB5.png?updatedAt=1678722644239";
export const wamb6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/WAM/benefits/wamB6.png?updatedAt=1678722644269";

//SEO Icons
export const seo1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO1.svg?updatedAt=1678674682381";
export const seo2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO2.svg?updatedAt=1678674682280";
export const seo3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO3.png?updatedAt=1678674736581";
export const seo4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO4.svg?updatedAt=1678674682307";
export const seo5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO5.png?updatedAt=1678674736615";
export const seo6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO6.png?updatedAt=1678674736577";
export const seo7Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO7.png?updatedAt=1678674736587";
export const seo8Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO8.png?updatedAt=1678674736651";
export const seo9Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO9.png?updatedAt=1678674736536";
export const seo10Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO10.png?updatedAt=1678674736575";
export const seo11Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO11.svg?updatedAt=1678674682285";
export const seo12Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/SEO12.png?updatedAt=1678674736644";
export const seoMainImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoMain.png?updatedAt=1678727001146";
export const seob1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoB1.png?updatedAt=1678727001141";
export const seob2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoB2.png?updatedAt=1678727001122";
export const seob3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoB3.png?updatedAt=1678727001174";
export const seob4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoB4.png?updatedAt=1678727001145";
export const seob5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoB5.png?updatedAt=1678727001061";
export const seob6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/SEO/benefits/seoB6.png?updatedAt=1678727001148";

//RND Icons
export const rnd1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd1.png?updatedAt=1678705780723";
export const rnd2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd2.png?updatedAt=1678705780785";
export const rnd3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd3.png?updatedAt=1678732336021";
export const rnd4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd4.png?updatedAt=1678705780754";
export const rnd5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd5.png?updatedAt=1678705780743";
export const rnd6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd6.png?updatedAt=1678705780715";
export const rnd7Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd7.png?updatedAt=1678705780702";
export const rnd8Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd8.png?updatedAt=1678705780722";
export const rnd9Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd9.png?updatedAt=1678705780864";
export const rnd10Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd10.png?updatedAt=1678705780862";
  export const rnd11Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/rnd11.png?updatedAt=1678705780712";
export const rndb1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/benefits/rndB1.png?updatedAt=1678727106887";
export const rndb2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/benefits/rndB2.png?updatedAt=1678727107137";
export const rndb3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/benefits/rndB3.png?updatedAt=1678727107075";
export const rndb4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/benefits/rndB4.png?updatedAt=1678727107150";
export const rndb5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/RND/benefits/rndB5.png?updatedAt=1678727107068";

//MAM Icons
export const mam1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam1.svg?updatedAt=1678706329228";
export const mam2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam2.png?updatedAt=1678706381876";
export const mam3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam3.png?updatedAt=1678706382293";
export const mam4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam4.svg?updatedAt=1678706329166";
export const mam5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam5.png?updatedAt=1678706382193";
export const mam6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam6.png?updatedAt=1678706382181";
export const mam7Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam7.png?updatedAt=1678706382211";
export const mam8Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam8.png?updatedAt=1678706382300";
export const mam9Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam9.png?updatedAt=1678706382271";
export const mam10Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/mam10.png?updatedAt=1678706382198";
export const mamMainImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamBmain.png?updatedAt=1678727243360";
export const mamb1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamB1.png?updatedAt=1678727243254";
export const mamb2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamB2.png?updatedAt=1678727243255";
export const mamb3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamB3.png?updatedAt=1678727243362";
export const mamb4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamB4.png?updatedAt=1678727243532";
export const mamb5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamB5.png?updatedAt=1678727243369";
export const mamb6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAM/benefits/mamB6.png?updatedAt=1678727243544";

//MAD Icons
export const mad1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad1.png?updatedAt=1678706790024";
export const mad2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad2.png?updatedAt=1678706790317";
export const mad3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad3.png?updatedAt=1678706790385";
export const mad4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad4.png?updatedAt=1678706790024";
export const mad5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad5.png?updatedAt=1678706790393";
export const mad6Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad6.png?updatedAt=1678706790446";
export const mad7Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad7.png?updatedAt=1678706790272";
export const mad8Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad8.svg?updatedAt=1678706710978";
export const mad9Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad9.png?updatedAt=1678706790443";
export const mad10Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/mad10.svg?updatedAt=1678706711223";
export const madMainImage =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/benefits/madBMain.png?updatedAt=1678727303131";
export const madb1Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/benefits/madB1.png?updatedAt=1678727303126";
export const madb2Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/benefits/madB2.png?updatedAt=1678727303092";
export const madb3Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/benefits/madB3.png?updatedAt=1678727303177";
export const madb4Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/benefits/madB4.png?updatedAt=1678727303260";
export const madb5Image =
  "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/MAD/benefits/madB5.png?updatedAt=1678727303182";


  //Client Quotation Images
  export const clientInfoImage = "https://ik.imagekit.io/0hhctrxcv/thanweconsultants/client_info_budget.jpeg?updatedAt=1679049902164";