import Aos from "aos";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import { useLocation } from "react-router-dom";
import smoothScrollPolyfill from "smoothscroll-polyfill";

import CustomChatBot from "./Components/Chatbot/CustomChatBot";
import Fallbacksuspense from "./Components/Fallbacksuspense";
import ReactHotToastContainer from "./Components/Notifications/ToastContainer";
import ScrollUp from "./Components/Scrolls/ScrollUp";
import DrawerContext from "./Context/DrawerContext";
import { ProtectedRouter } from "./ProtectedRouter";
import ScrollOnTop from "./ScrollOnTop";
import { GlobalDebug } from "./utils/remove-console";

//Lazy Components/Screens
const LazyHomeScreen = React.lazy(() => import("./Screens/HomeScreen"));
const LazyNotFound = React.lazy(() => import("./Screens/NotFound"));
const LazyWebAppSupportAndMaintenance = React.lazy(() =>
  import("./Screens/OurServices/WebAppSupportAndMaintenance")
);
const LazyInquireForm = React.lazy(() => import("./Screens/InquireForm"));
const LazyContactScreen = React.lazy(() => import("./Screens/ContactScreen"));
const LazyServicesScreen = React.lazy(() => import("./Screens/ServicesScreen"));
const LazyAboutUsScreen = React.lazy(() => import("./Screens/AboutUsScreen"));
const LazyMobileAppDesign = React.lazy(() =>
  import("./Screens/OurServices/MobileAppDesign")
);
const LazyReactNativeAppDevelopment = React.lazy(() =>
  import("./Screens/OurServices/ReactNativeAppDevelopment")
);
const LazySearchEngineOptimization = React.lazy(() =>
  import("./Screens/OurServices/SearchEngineOptimization")
);
const LazyMobileAppMaintenance = React.lazy(() =>
  import("./Screens/OurServices/MobileAppMaintenance")
);
const LazyWebsitePlanningDocument = React.lazy(() =>
  import("./Components/Forms/WebsitePlanningDocument")
);
const LazyClientPreviewForm = React.lazy(() =>
  import("./Screens/Forms/ClientPreviewForm")
);
const LazyLogin = React.lazy(() => import("./Screens/Dashboard/Login"));
const LazyPhoneLogin = React.lazy(() =>
  import("./Screens/Dashboard/PhoneAuth")
);
const LazyDashboard = React.lazy(() =>
  import("./Screens/Dashboard/Admin/Dashboard")
);
const LazyBudgetCalculator = React.lazy(() =>
  import("./Screens/Dashboard/Admin/BudgetCalculator")
);
const LazyAnalytics = React.lazy(() =>
  import("./Screens/Dashboard/Admin/Analytics")
);
const LazyWebAppDevelopment = React.lazy(() =>
  import("./Screens/OurServices/WebAppDevelopment")
);
const LazyNewpassword = React.lazy(() =>
  import("./Screens/Dashboard/Newpassword")
);
const LazyForgotpassword = React.lazy(() =>
  import("./Screens/Dashboard/Forgotpassword")
);
const LazySettings = React.lazy(() =>
  import("./Screens/Dashboard/Admin/Settings")
);
const LazyClientBoard = React.lazy(() =>
  import("./Screens/Dashboard/Admin/ClientBoard")
);

function App() {
  
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_STAGING_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);

  smoothScrollPolyfill.polyfill();
  Aos.init();
  const { pathname } = useLocation();
  return (
    <>
      <ReactHotToastContainer />
      <DrawerContext>
        <ScrollOnTop>
          <Routes>
            {/* ************* PUBLIC ROUTES **************** */}
            <Route
              path="/"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyHomeScreen />
                </React.Suspense>
              }
            />
            <Route
              path="/contact-us"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyContactScreen />
                </React.Suspense>
              }
            />
            <Route
              path="/about-us"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyAboutUsScreen />
                </React.Suspense>
              }
            />
            <Route
              path="/services"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyServicesScreen />
                </React.Suspense>
              }
            />
            <Route
              path="/inquire_form"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyInquireForm />
                </React.Suspense>
              }
            />
            <Route
              path="/wad"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyWebAppDevelopment />
                </React.Suspense>
              }
            />
            <Route
              path="/wasm"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyWebAppSupportAndMaintenance />
                </React.Suspense>
              }
            />
            <Route
              path="/seo"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazySearchEngineOptimization />
                </React.Suspense>
              }
            />
            <Route
              path="/rnad"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyReactNativeAppDevelopment />
                </React.Suspense>
              }
            />
            <Route
              path="/mad"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyMobileAppDesign />
                </React.Suspense>
              }
            />
            <Route
              path="/mam"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyMobileAppMaintenance />
                </React.Suspense>
              }
            />
            <Route
              path="/thanweconsultants/clientRequirementForm/:id"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyWebsitePlanningDocument />
                </React.Suspense>
              }
            />
            <Route
              path="/client_preview_form"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyClientPreviewForm />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/login"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyLogin />
                </React.Suspense>
              }
            />
            <Route
              exact
              path="/phoneLogin"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyPhoneLogin />
                </React.Suspense>
              }
            />
            <Route
              path="/forgotpassword"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyForgotpassword />
                </React.Suspense>
              }
            />
            <Route
              path="/newpassword"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyNewpassword />
                </React.Suspense>
              }
            />
            <Route
              path="*"
              element={
                <React.Suspense fallback={<Fallbacksuspense />}>
                  <LazyNotFound />
                </React.Suspense>
              }
            />
            {/* <Route
                path="/fall"
                element={
                  <React.Suspense fallback={<Fallbacksuspense />}>
                    <Fallbacksuspense />
                  </React.Suspense>
                }
              /> */}
            {/* ************* PRIVATE ROUTES **************** */}
            <Route element={<ProtectedRouter />}>
              <Route
                path="/dashboard"
                element={
                  <React.Suspense fallback={<Fallbacksuspense />}>
                    <LazyDashboard />
                  </React.Suspense>
                }
              />
              <Route
                path="/analytics"
                element={
                  <React.Suspense fallback={<Fallbacksuspense />}>
                    <LazyAnalytics />
                  </React.Suspense>
                }
              />
              <Route
                path="/client_board"
                element={
                  <React.Suspense fallback={<Fallbacksuspense />}>
                    <LazyClientBoard />
                  </React.Suspense>
                }
              />
              <Route
                path="/settings"
                element={
                  <React.Suspense fallback={<Fallbacksuspense />}>
                    <LazySettings />
                  </React.Suspense>
                }
              />
               <Route
                path="/budget_calculator"
                element={
                  <React.Suspense fallback={<Fallbacksuspense />}>
                    <LazyBudgetCalculator />
                  </React.Suspense>
                }
              />
            </Route>
          </Routes>
        </ScrollOnTop>
      </DrawerContext>
      <ScrollUp />
      {pathname === "/client_board" ||
      pathname === "/login" ||
      pathname === "/phoneLogin" ||
      pathname === "/forgotpassword" ||
      pathname === "/newpassword" ||
      pathname === "/dashboard" ||
      pathname === "/settings" ||
      pathname === "/budget_calculator" ||
      pathname === "/client_preview_form" ||
      pathname?.includes("clientRequirementForm") ? null : (
        <CustomChatBot />
      )}
    </>
  );
}

export default App;
