import * as userConstants from "../Constants/UserConstants";

// LOGIN
export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case userConstants.USER_LOGIN_REQUEST:
      return { isLoading: true };
    case userConstants.USER_LOGIN_SUCCESS:
      return {
        isLoading: false,
        thanWeUserInfo: action.payload,
        isSuccess: true,
      };
    case userConstants.USER_LOGIN_FAIL:
      return { isLoading: false, isError: formatErrors(action.payload) };
    case userConstants.USER_LOGIN_RESET:
      return {};
    case userConstants.USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

const formatErrors = (error) => {
  if (error.includes("auth/email-already-in-use")) {
    return "Email is already in use";
  }
  if (error.includes("auth/weak-password")) {
    return "Password should be at least 6 characters";
  }
  if (error.includes("auth/user-not-found")) {
    return "User not found!";
  }
  if (error.includes("auth/wrong-password")) {
    return "Wrong password entered";
  } else {
    return error;
  }
};
