import { acknowledgementEmailTemplate } from "./Templates/AcknowledmentEmailTemplate";
import { clientRequestEmailTemplate } from "./Templates/ClientRequestEmailTemplate";
import { finalSubmissionEmailTemplate } from "./Templates/FinalSubmissionEmailTemplate";

const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: "smooth",
  });
};

const finalSubmissionEmailFormat = (name, link) => {
  return finalSubmissionEmailTemplate(name, link);
};

const clientContactDetailsEmailFormat = (
  name,
  message,
  email,
  phone_number,
  link,
  workorder_id
) => {
  return clientRequestEmailTemplate(
    name,
    message,
    email,
    phone_number,
    link,
    workorder_id
  );
};

const acknowledgementEmailFormat = (name, link) => {
  return acknowledgementEmailTemplate(name, link);
};

const removeHtmlTags = (myStr) => {
  // /(<([^>]+)>)/gi
  if (myStr === null || myStr === "") return "";
  else myStr = myStr.toString();
  console.log(myStr);
  return myStr.replace(/(<([^>]+)>)/gi, "");
};

const filterValue = (data, filterName) => {
  return data?.filter((x) => x.name === filterName)[0]?.label;
};

export {
  scrollWithOffset,
  finalSubmissionEmailFormat,
  clientContactDetailsEmailFormat,
  acknowledgementEmailFormat,
  removeHtmlTags,
  filterValue
};
