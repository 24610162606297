import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import * as clientForm from "./Reducers/ClientFormReducer";
import * as User from "./Reducers/UserReducer";

const rootReducer = combineReducers({
  // user reducers
  userLogin: User.userLoginReducer,

  // client form reducers
  addClientForm: clientForm.addClientFormReducer,
  getClientFormDetailsData: clientForm.getClientFormDetailsReducer,
  getFullClientFormData: clientForm.getFullClientFormDataReducer,
  getFullClientContactFormData: clientForm.getFullClientContactFormDataReducer,
});

// get thanWeUserInfoFromStorage from  localStorage
const thanWeUserInfoFromStorage = localStorage.getItem("thanWeUserInfo")
  ? JSON.parse(localStorage.getItem("thanWeUserInfo"))
  : null;

  // initialState
const initialState = {
  userLogin: { thanWeUserInfo: thanWeUserInfoFromStorage },
};
const persistConfig = {
  key: "root",
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);



export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  devTools:
    process.env.NODE_ENV !== "production" &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
      ? (a) => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__(),
  middleware: [thunk],
});

export const persistor = persistStore(store);
