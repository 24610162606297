import React from "react";
import { BsFillArrowUpSquareFill } from "react-icons/bs";
import ScrollToTop from "react-scroll-up";

function ScrollUp() {
  return (
    <ScrollToTop showUnder={160} style={{ right: 45, bottom: 100 }}>
      <BsFillArrowUpSquareFill className="w-8 h-8 text-black" />
    </ScrollToTop>
  );
}

export default ScrollUp;
