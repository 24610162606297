import { FaReact } from "react-icons/fa";
import { GiHealthPotion } from "react-icons/gi";
import { IoMdRestaurant } from "react-icons/io";
import {
  MdEmojiTransportation,
  MdLocalGroceryStore,
  MdRealEstateAgent,
} from "react-icons/md";
import { SiWebhint, SiYourtraveldottv } from "react-icons/si";
import {
  fiveImage,
  mad10Image,
  mad1Image,
  mad2Image,
  mad3Image,
  mad4Image,
  mad5Image,
  mad6Image,
  mad7Image,
  mad8Image,
  mad9Image,
  madb1Image,
  madb2Image,
  madb3Image,
  madb4Image,
  madb5Image,
  mam10Image,
  mam1Image,
  mam2Image,
  mam3Image,
  mam4Image,
  mam5Image,
  mam6Image,
  mam7Image,
  mam8Image,
  mam9Image,
  mamb1Image,
  mamb2Image,
  mamb3Image,
  mamb4Image,
  mamb5Image,
  mamb6Image,
  oneImage,
  rnd10Image,
  rnd11Image,
  rnd1Image,
  rnd2Image,
  rnd3Image,
  rnd4Image,
  rnd5Image,
  rnd6Image,
  rnd7Image,
  rnd8Image,
  rnd9Image,
  rndb1Image,
  rndb2Image,
  rndb3Image,
  rndb4Image,
  rndb5Image,
  seo10Image,
  seo11Image,
  seo12Image,
  seo1Image,
  seo2Image,
  seo3Image,
  seo4Image,
  seo5Image,
  seo6Image,
  seo7Image,
  seo8Image,
  seo9Image,
  seob1Image,
  seob2Image,
  seob3Image,
  seob4Image,
  seob5Image,
  seob6Image,
  sixImage,
  threeImage,
  twoImage,
  wad1Image,
  wad2Image,
  wad3Image,
  wad4Image,
  wad5Image,
  wad6Image,
  wad7Image,
  wad8Image,
  wadAccessibilityImage,
  wadCostEffectiveImage,
  wadCrossPlatformImage,
  wadScalabilityImage,
  wam10Image,
  wam1Image,
  wam2Image,
  wam3Image,
  wam4Image,
  wam5Image,
  wam6Image,
  wam7Image,
  wam8Image,
  wam9Image,
  wamb1Image,
  wamb2Image,
  wamb3Image,
  wamb4Image,
  wamb5Image,
  wamb6Image,
} from "../utils/imageConstants";

export const servicesData = [
  {
    id: 1,
    Icon: oneImage,
    isIcon: false,
    name: "Web App Development",
    path: "/wad",
    description:
      "Web application development is the creation of application programs that reside on remote servers and are delivered to the user's device over the Internet. A web application (web app) does not need to be downloaded and is instead accessed through a network",
  },
  {
    id: 2,
    Icon: twoImage,
    isIcon: false,
    name: "Web App Support & Maintenance",
    path: "/wasm",
    description:
      "Application maintenance and support service may include expansion and/or modification of the applications functionality, debugging and creation of supplementary solutions. Applications management service includes the following steps: application transition; application maintenance and support",
  },
  {
    id: 3,
    Icon: threeImage,
    isIcon: false,
    name: "Search Engine Optimization",
    path: "/seo",
    description:
      "Search engine optimization is the process of improving the quality and quantity of website traffic to a website or a web page from search engines. SEO targets unpaid traffic rather than direct traffic or paid traffic.",
  },
  {
    id: 4,
    Icon: rnd3Image,
    isIcon: false,
    name: "React Native App Development",
    path: "/rnad",
    description:
      "React Native (also known as RN) is a popular JavaScript-based mobile app framework that allows you to build natively-rendered mobile apps for iOS and Android. The framework lets you create an application for various platforms by using the same codebase",
  },
  {
    id: 5,
    Icon: fiveImage,
    isIcon: false,
    name: "Mobile App Design",
    path: "/mad",
    description:
      "App design combines the user interface (UI) and user experience (UX). While UI lends itself to the overall style of the app (including the colors, fonts, and general look and feel), UX focuses on the actual functionality and usability. A large number of users abandon an app after using it for the first time",
  },
  {
    id: 6,
    Icon: sixImage,
    isIcon: false,
    name: "Mobile App Maintenance",
    path: "/mam",
    description:
      "Mobile app maintenance is the collection of myriad processes included to keep a mobile app active, updated, error-free, supportive to users for hardware and software, performance monitoring, and fixing bugs in mobile applications.",
  },
];

export const technologiesData = [
  {
    id: 1,
    label: "iOS",
    value: "ios",
  },
  {
    id: 2,
    label: "Android",
    value: "android",
  },
  {
    id: 3,
    label: "Web",
    value: "web",
  },
  {
    id: 4,
    label: "UI/UX Design",
    value: "uiuxdesign",
  },
  {
    id: 5,
    label: "Other",
    value: "other",
  },
];

export const reactNativeData = [
  {
    id: 1,
    Icon: rnd1Image,
    isIcon: false,
    name: "React Native consulting",
    path: "",
    description:
      "Most billion-dollar app ideas remain only ideas because they lack the right guidance on selecting the right development framework and strategy. We’ve got both. Our consulting helps you launch a perfect React Native app with the right list of features.",
  },
  {
    id: 2,
    Icon: rnd2Image,
    isIcon: false,
    name: "Mobile app design",
    path: "",
    description:
      "Mobile app design is the process of creating the visual and interactive interface for a mobile application. A good mobile app design is essential to the success of your app, as it plays a crucial role in attracting and retaining users.",
  },
  {
    id: 3,
    Icon: rnd3Image,
    isIcon: false,
    name: "React Native app development",
    path: "",
    description:
      "Our team of experienced React Native developers can help you build custom mobile applications that meet your unique business needs. Whether you need an e-commerce app, social media app, or any other type of mobile application, we can help you bring your ideas to life.",
  },
  {
    id: 4,
    Icon: rnd4Image,
    isIcon: false,
    name: "Legacy App migration to React Native",
    path: "",
    description:
      "Migrating your existing mobile application to React Native can be a cost-effective and efficient way to enhance its performance, scalability, and user experience. Contact us to learn more about our app migration services and how we can help you take your app to the next level.",
  },
  {
    id: 5,
    Icon: rnd5Image,
    isIcon: false,
    name: "App maintenance and support",
    path: "",
    description:
      "App maintenance and support involves ongoing monitoring, troubleshooting, and updating of your mobile application to ensure that it continues to meet the needs of your users and stays up-to-date with the latest mobile trends and technologies. With our services, you can be assured of a reliable and well-maintained app that meets the needs of your users and your business",
  },
];

export const whyThanWeConsultantsData = [
  {
    id: 1,
    Icon: rnd6Image,
    isIcon: false,
    name: "Expertise and Experience",
    path: "",
    description:
      "Our team has extensive experience and expertise in React Native development. We have successfully delivered numerous React Native projects for clients in various industries, from startups to large enterprises.",
  },
  {
    id: 2,
    Icon: rnd7Image,
    isIcon: false,
    name: "High-Quality Standards",
    path: "",
    description:
      "We are committed to delivering high-quality applications that meet the needs of our clients and their users. We follow industry best practices and use the latest development tools and technologies to ensure that our applications are scalable, reliable, and secure.",
  },
  {
    id: 3,
    Icon: rnd8Image,
    isIcon: false,
    name: "Customized Solutions",
    path: "",
    description:
      "We understand that every business has unique requirements, and we work closely with our clients to understand their needs and develop customized solutions that meet those needs.",
  },
  {
    id: 4,
    Icon: rnd9Image,
    isIcon: false,
    name: "Cost-Effective Solutions",
    path: "",
    description:
      "We offer cost-effective React Native development services that help our clients achieve their goals without breaking the bank. Our focus on efficiency and productivity allows us to deliver projects on time and within budget.",
  },
  {
    id: 5,
    Icon: rnd10Image,
    isIcon: false,
    name: "Ongoing Support and Maintenance",
    path: "",
    description:
      "We are committed to providing ongoing support and maintenance for our clients' applications. We offer comprehensive maintenance and support services to ensure that our applications remain up-to-date and continue to meet the evolving needs of our clients and their users.",
  },
  {
    id: 6,
    Icon: rnd11Image,
    isIcon: false,
    name: "Customer Satisfaction",
    path: "",
    description:
      "Our top priority is customer satisfaction. We work closely with our clients to ensure that they are fully satisfied with the solutions we deliver, and we are always available to answer questions, provide support, and address any issues that may arise.",
  },
];

export const developmentSkillsData = [
  "React Native",
  "Redux",
  "React Navigation",
  "Moti",
];

export const rnPlanningData = [
  {
    name: "Planning",
    width: 300,
    height: 150,
    zIndex: 100,
    description:
      "Planning is a list of actions arranged in whatever sequence is thought likely to achieve an objective. We define project goals, establish timelines and milestones, allocate resources, and identify potential risks and challenges before starting with the development process. By having a clear plan in place, we can ensure that your requirement stays on track and meets its objectives.",
  },
  {
    name: "Design",
    width: 400,
    height: 200,
    zIndex: 30,
    description:
      "Design is not just what it looks like and feels like. Design is how it works. We focus on user-centric design principles, and create software that is intuitive, easy to use, and efficient. We creates the architecture and design of the software system, including the user interface, database structure, and programming language. ",
  },
  {
    name: "Development",
    width: 500,
    height: 250,
    zIndex: 10,
    description:
      "Any fool can write code that a computer can understand. Good programmers write code that humans can understand. We develop and codes the software system based on the design specifications.",
  },
  {
    name: "Quality assurance",
    width: 400,
    height: 200,
    zIndex: 30,
    description:
      "The sooner you find bugs, the cheaper they are to fix. We test the developed software to ensure it meets the functional and non-functional requirements defined in the planning phase. We ensure that our product meets the highest quality standards, providing our customers with a seamless and efficient experience.",
  },
  {
    name: "Support",
    width: 300,
    height: 150,
    zIndex: 50,
    description:
      "The foundation of a great customer experience is an effective and efficient support team. We have such a team at ThanWe to provide ongoing support and maintenance for the software, including bug fixes, updates, and enhancements.",
  },
];

export const pickOutNicheData = [
  {
    id: 1,
    Icon: MdLocalGroceryStore,
    name: "Ecommerce",
  },
  {
    id: 2,
    Icon: GiHealthPotion,
    name: "Health Care",
  },
  {
    id: 3,
    Icon: IoMdRestaurant,
    name: "Restaurant",
  },
  {
    id: 4,
    Icon: SiYourtraveldottv,
    name: "Travel",
  },
  {
    id: 5,
    Icon: MdEmojiTransportation,
    name: "Transport",
  },
  {
    id: 6,
    Icon: MdRealEstateAgent,
    name: "Real Estate",
  },
];

export const GoalsData = [
  {
    id: "1",
    name: "sellingProductsServicesOnline",
    label: "Selling products / services online",
  },
  {
    id: "2",
    name: "eventBooking",
    label: "Event booking",
  },
  {
    id: "3",
    name: "bookingOfAService",
    label: "Booking of a service",
  },
  {
    id: "4",
    name: "leadGeneration",
    label: "Lead Generation",
  },
  {
    id: "5",
    name: "onlineMarketing",
    label: "Online marketing",
  },
  {
    id: "6",
    name: "membershipSite",
    label: "Membership site",
  },
];

export const StandardPagesData = [
  {
    id: "7",
    name: "home",
    label: "Home",
  },
  {
    id: "8",
    name: "about",
    label: "About",
  },
  {
    id: "9",
    name: "services",
    label: "Services",
  },
  {
    id: "10",
    name: "products",
    label: "Products",
  },
  {
    id: "11",
    name: "contact",
    label: "Contact",
  },
  {
    id: "career",
    name: "career",
    label: "Career",
  },
  {
    id: "gallery",
    name: "gallery",
    label: "Gallery",
  },
  {
    id: "productDetail",
    name: "productDetail",
    label: "Product Detail",
  },
  {
    id: "portfolio",
    name: "portfolio",
    label: "Portfolio",
  },
  {
    id: "media",
    name: "media",
    label: "Media",
  },
  {
    id: "testimonial",
    name: "testimonial",
    label: "Testimonial",
  },
  {
    id: "clients",
    name: "clients",
    label: "Clients",
  },
  {
    id: "enquiry",
    name: "enquiry",
    label: "Enquiry",
  },
  {
    id: "getAQuote",
    name: "getAQuote",
    label: "Get A Quote",
  },
  {
    id: "blog",
    name: "blog",
    label: "Blog",
  },
  {
    id: "visionPage",
    name: "visionPage",
    label: "Vision Page",
  },
  {
    id: "missionPage",
    name: "missionPage",
    label: "Mission Page",
  },
  {
    id: "team",
    name: "team",
    label: "Team",
  },
  {
    id: "faqs",
    name: "faqs",
    label: "Faq's",
  },
  {
    id: "news",
    name: "news",
    label: "News",
  },
  {
    id: "events",
    name: "events",
    label: "Events",
  },
  {
    id: "awards",
    name: "awards",
    label: "Awards",
  },
  {
    id: "achievement",
    name: "achievement",
    label: "Achievement",
  },
  {
    id: "csr",
    name: "csr",
    label: "CSR",
  },
  {
    id: "termsAndConditions",
    name: "termsAndConditions",
    label: "Terms & Conditions",
  },
  {
    id: "privacyPolicy",
    name: "privacyPolicy",
    label: "Privacy Policy",
  },
  {
    id: "refundPolicy",
    name: "refundPolicy",
    label: "Refund Policy",
  },
  {
    id: "cancellationPolicy",
    name: "cancellationPolicy",
    label: "Cancellation Policy",
  },
  {
    id: "studies",
    name: "studies",
    label: "Studies",
  },
  {
    id: "industries",
    name: "industries",
    label: "Industries",
  },
  {
    id: "siteMap",
    name: "siteMap",
    label: "Site Map",
  },
  {
    id: "complaint",
    name: "complaint",
    label: "Complaint",
  },
];

export const FunctionalityData = [
  {
    id: "12",
    name: "ecommerce(sellingonline)",
    label: "E-Commerce (selling online)",
  },
  {
    id: "13",
    name: "eventcalendar",
    label: "Event Calendar",
  },
  {
    id: "14",
    name: "eventbookings",
    label: "Event Bookings",
  },
  {
    id: "15",
    name: "blogsrss",
    label: "Blogs / RSS",
  },
  {
    id: "16",
    name: "securezones",
    label: "Secure Zones / Membership Areas",
  },
  {
    id: "17",
    name: "faq",
    label: "Frequently Asked Questions",
  },
  {
    id: "18",
    name: "photogalleries",
    label: "Photo Galleries",
  },
  {
    id: "19",
    name: "videogalleries",
    label: "Video Galleries",
  },
  {
    id: "20",
    name: "paymentcollection",
    label: "Payment Collection",
  },
  {
    id: "21",
    name: "socialmediaintegration",
    label: "Social Media Integration",
  },
  {
    id: "22",
    name: "slideshow",
    label: "Slideshow",
  },
  {
    id: "23",
    name: "webforms",
    label: "Web Forms",
  },
  {
    id: "dynamicBanner",
    name: "dynamicBanner",
    label: "Dynamic Banner",
  },
  {
    id: "popUpEnquiry",
    name: "popUpEnquiry",
    label: "Pop Up Enquiry",
  },
  {
    id: "chatBot",
    name: "chatBot",
    label: "Chat Bot",
  },
  {
    id: "liveChat",
    name: "liveChat",
    label: "Live Chat",
  },
  {
    id: "smsUpdates",
    name: "smsUpdates",
    label: "SMS Updates",
  },
  {
    id: "newsletterEmails",
    name: "newsletterEmails",
    label: "Newsletter Emails",
  },
];

export const yesOrnoData = [
  {
    id: "24",
    name: "yes",
    label: "Yes",
  },
  {
    id: "25",
    name: "no",
    label: "No",
  },
];

export const yesOrnoDataForDomain = [
  {
    id: "26",
    name: "yes",
    label: "Yes",
  },
  {
    id: "27",
    name: "no",
    label: "No",
  },
];

export const additionalServicesData = [
  {
    id: "28",
    name: "graphic_design",
    label:
      "GRAPHIC DESIGN - This includes icons, print advertising, package design.",
  },
  {
    id: "29",
    name: "logo_design",
    label: "LOGO DESIGN - Professional logo design or redesign for your brand.",
  },
  {
    id: "30",
    name: "image_processing",
    label: "IMAGE PROCESSING - Images that need retouching or optimising.",
  },
  {
    id: "31",
    name: "searchable_databases",
    label:
      "SEARCHABLE DATABASES - Such as yell.com, real estate listings or classified ads.",
  },
  {
    id: "32",
    name: "social_media_running",
    label:
      "SOCIAL MEDIA RUNNING - Management of a variety of platforms with creative content.",
  },
  {
    id: "33",
    name: "social_media_ads",
    label:
      "SOCIAL MEDIA ADS - Original content driven to directly target your audience.",
  },
  {
    id: "34",
    name: "pay_per_click",
    label:
      "PAY-PER-CLICK - Effective and budget friendly advertising on an expert level.",
  },
  {
    id: "35",
    name: "maintenance",
    label:
      "MAINTENANCE - A monthly package with minor add-ons, changes to your site.",
  },
  {
    id: "36",
    name: "seo_upgrade",
    label:
      "SEO UPGRADE - Monthly review and adjustment for optimal visability.",
  },
];

export const OSData = [
  {
    id: "37",
    name: "mobile",
    label: "Mobile",
  },
  {
    id: "38",
    name: "desktop",
    label: "Desktop",
  },
  {
    id: "39",
    name: "mobile_And_Desktop",
    label: "Mobile And Desktop",
  },
];

export const requirementsData = [
  { key: "Web App Development", cat: "Group 1" },
  { key: "Web App Support & Maintenance", cat: "Group 1" },
  { key: "Search Engine Optimization", cat: "Group 1" },
  { key: "React Native App Development", cat: "Group 1" },
  { key: "Mobile App Design", cat: "Group 1" },
  { key: "Mobile App Maintenance", cat: "Group 1" },
];



export const wasmServicesData = [
  {
    id: "87",
    name: "websiteServerMaintenance",
    label: "Website Server Maintenance",
    children: ["Regular Updates", "Uptime Monitoring and Real-time Management"],
  },
  {
    id: "88",
    name: "databaseAdministration",
    label: "Database Administration",
    children: ["Database Maintenance", "Data Purging"],
  },
  {
    id: "89",
    name: "websiteSecurityAudit",
    label: "Website Security Audit",
    children: [
      "Updating and Maintaining Security Protocols",
      "Checking of   Web Application Security & Vulnerability",
    ],
  },
  {
    id: "90",
    name: "websiteCodingAssessment",
    label: "Website Coding Assessment",
    children: [
      "Bug Fixing",
      "Technical and Functional Enhancements",
      "Optimization for New Browser Versions",
      "Improve User experience",
    ],
  },
  {
    id: "91",
    name: "websitePerformanceAudit",
    label: "Website Performance Audit",
    children: [
      "Performance reports",
      "Boost User access performance",
      "Testing and Debugging of Software",
    ],
  },
  {
    id: "92",
    name: "customerWebSupport",
    label: "Customer Web support",
    children: ["Telephone & Mail Support"],
  },
  {
    id: "93",
    name: "other",
    label: "Other",
    children: [
      "3rd Party Integrations",
      "Technical Documentation",
      "Research and Analysis",
    ],
  },
];

export const wasmData = [
  {
    id: "wasm_about_your_app",
    title: `Can you tell us a little bit about your web app? What does it do, and
          what is its purpose?`,
    name: "wasm_about_your_app",
  },
  {
    id: "wasm_general_support",
    title: `Are you experiencing any specific issues with your web app, or are you looking for general maintenance and support?`,
    name: "wasm_general_support",
  },
  {
    id: "wasm_level_of_support",
    title: `What level of support are you looking for? For example, do you need 24/7 support, or are regular business hours sufficient?`,
    name: "wasm_level_of_support",
  },
  {
    id: "wasm_budget",
    title: `What is your budget for web app support and maintenance services?`,
    name: "wasm_budget",
  },
  {
    id: "wasm_timeline_services",
    title: ` What is your timeline for getting started with these services?`,
    name: "wasm_timeline_services",
  },
  {
    id: "wasm_specific_features",
    title: `Are there any specific features or functionalities you are looking to add or improve in your web app?`,
    name: "wasm_specific_features",
  },
];

export const WebSiteTypeData = [
  {
    id: "94",
    name: "static",
    label: "Static Website",
  },
  {
    id: "95",
    name: "dynamic",
    label: "Dynamic Website",
  },
  {
    id: "96",
    name: "business",
    label: "Business Website",
  },
  {
    id: "97",
    name: "ecommerce",
    label: "Ecommerce Website",
  },
  {
    id: "98",
    name: "service_providing_website",
    label: "Service providing website (Streaming/online tools etc.)",
  },
];

export const numberOfPagesData = [
  {
    id: "oneToFour",
    name: "oneToFour",
    label: "1 - 4",
  },
  {
    id: "fiveToEight",
    name: "fiveToEight",
    label: "5 - 8",
  },
  {
    id: "nineToTwenty",
    name: "nineToTwenty",
    label: "9 - 20",
  },
  {
    id: "twentyToTwentyFive",
    name: "twentyToTwentyFive",
    label: "20 - 25",
  },
  {
    id: "unlimited",
    name: "unlimited",
    label: "Unlimited",
  },
];

//Web App Development Data

export const wadBenefitsData = [
  {
    id: "Accessibility",
    name: "Accessibility",
    description: ` Web apps are accessible from any
    device with an internet connection, making them highly convenient
    for users.`,
    image: wadAccessibilityImage,
  },
  {
    id: "Scalability",
    name: "Scalability",
    description: `Web apps can handle a large number of
    users simultaneously without any issues. They can also be easily
    scaled up or down as per the needs of the business.`,
    image: wadScalabilityImage,
  },
  {
    id: "Cross-platform compatibility",
    name: "Cross-platform compatibility",
    description: `Web apps can run on
    any device, regardless of the operating system or hardware
    configuration.`,
    image: wadCrossPlatformImage,
  },
  {
    id: "Cost-effective",
    name: "Cost-effective",
    description: `Web app development is generally
    less expensive than developing native desktop applications.`,
    image: wadCostEffectiveImage,
  },
];

export const webAppDevelopmentSkillsData = [
  "Next Js",
  "React",
  "Redux",
  "React Router Dom",
  "Mongo DB",
  "Firebase",
];

export const wadServicesData = [
  {
    id: 1,
    Icon: wad1Image,
    isIcon: false,
    name: "Requirements analysis",
    path: "",
    description:
      "As people say, when the path is clear, half the task is done. We at ThanWe understand the needs and objectives of your business and determine the features and functionality required in the web application.",
  },
  {
    id: 2,
    Icon: wad2Image,
    isIcon: false,
    name: "Design and architecture",
    path: "",
    description:
      "A unique and memorable design can help businesses differentiate themselves in crowded markets. We at ThanWe create the overall design and architecture of the web application, including the user interface, database structure, and application logic as per the customer requirement.",
  },
  {
    id: 3,
    Icon: wad3Image,
    isIcon: false,
    name: "Web App Development",
    path: "",
    description:
      "We at ThanWe use latest technologies, for front-end development, back-end development, databases, and cloud services. We also integrate third-party APIs and services, such as payment gateways or social media platforms. Also thoroughly test the web application to ensure that it functions correctly and meets the requirements of the business or organization.",
  },
  {
    id: 4,
    Icon: wad4Image,
    isIcon: false,
    name: "Web App maintenance",
    path: "",
    description:
      "The maintenance phase involves providing ongoing support and maintenance to the web application to ensure that it remains functional and up-to-date. This may involve updating software components, fixing bugs, and adding new features over time. We at ThanWe ensure that web applications remain secure, performant, and user-friendly over time.",
  },
];

export const whyThanWeConsultantsforWadData = [
  {
    id: 1,
    Icon: wad5Image,
    isIcon: false,
    name: "User-centric design",
    path: "",
    description:
      "A user-centric design approach ensures that the web app is designed keeping the end-users in mind. It helps in creating an intuitive and user-friendly interface.",
  },
  {
    id: 2,
    Icon: wad6Image,
    isIcon: false,
    name: "Responsive design",
    path: "",
    description:
      "A responsive design ensures that the web app is optimized for all screen sizes and devices, providing an optimal user experience.",
  },
  {
    id: 3,
    Icon: wad7Image,
    isIcon: false,
    name: "Security",
    path: "",
    description:
      "Security should be a top priority during web app development. Developers should ensure that the app is secure from cyber threats and data breaches.",
  },
  {
    id: 4,
    Icon: wad8Image,
    isIcon: false,
    name: "Testing and optimization",
    path: "",
    description:
      "Testing and optimization help in identifying and fixing any bugs or issues in the web app before it goes live. It also helps in optimizing the app for speed and performance.",
  },
];

//React Native App Development
export const rndaBenefitsData = [
  {
    id: "Cross-platform compatibility",
    name: "Cross-platform compatibility",
    description: `React Native apps can run seamlessly on both iOS and Android devices, with little to no modification.`,
    image: rndb1Image,
  },
  {
    id: "Large community and ecosystem",
    name: "Large community and ecosystem",
    description: `React Native has a large and active community of developers who contribute to the framework, creating a wide range of libraries and components that can be used in mobile app development.`,
    image: rndb2Image,
  },
  {
    id: "Rapid Development",
    name: "Rapid Development",
    description: `React Native's "hot reloading" feature enables developers to see changes to the app in real-time, which speeds up the development process and makes it easier to debug and troubleshoot issues.`,
    image: rndb3Image,
  },
  {
    id: "Reusability of code",
    name: "Reusability of code",
    description: `React Native allows for the reuse of code across multiple platforms, which can save time and effort.`,
    image: rndb4Image,
  },
  {
    id: "Open-source and free",
    name: "Open-source and free",
    description: `React Native is an open-source framework, which means that it is free to use and there are no licensing fees. This makes it an ideal choice for startups and small businesses with limited budgets.`,
    image: rndb5Image,
  },
];

// Web App Support And Maintenance Data
export const wasmServData = [
  {
    id: 1,
    Icon: wam1Image,
    isIcon: false,
    name: "Web Technical Support & Server Maintenance",
    path: "",
    description:
      "Technical Support involves addressing and resolving technical issues that may arise in the web application, such as bugs, errors, or performance issues. Website server maintenance refers to the process of regularly checking, updating, and optimizing the server to ensure that it runs smoothly and securely.",
  },
  {
    id: 2,
    Icon: wam2Image,
    isIcon: false,
    name: "Website Coding Assessment",
    path: "",
    description:
      "As the needs of the business change, web applications may need to be upgraded or enhanced to meet new requirements. This includes adding new features, improving functionality, or integrating with other systems. Also optimization the code for new browser versions.",
  },
  {
    id: 3,
    Icon: wam3Image,
    isIcon: false,
    name: "Website Security Audit",
    path: "",
    description:
      "Cybersecurity threats are constantly evolving, and it is important to ensure that web applications are protected against potential vulnerabilities. This involves implementing security patches, updates, and other measures to safeguard against attacks.",
  },
  {
    id: 4,
    Icon: wam4Image,
    isIcon: false,
    name: "Website Performance Audit",
    path: "",
    description:
      "Web applications must be optimized to perform efficiently and deliver fast response times. This may involve tuning the application, optimizing the database, or improving network connectivity.",
  },
  {
    id: 5,
    Icon: wam5Image,
    isIcon: false,
    name: "Database Administration",
    path: "",
    description:
      "Database Administration refers to the management and maintenance of a database system, which includes tasks such as User account management, Backup & recovery, future capacity planning, data archiving & purging, security & performance monitoring etc.",
  },
  {
    id: 6,
    Icon: wam6Image,
    isIcon: false,
    name: "Website Management",
    path: "",
    description:
      "Apart from the regular activities, we manage website content, end-user training, maintain technical documentation, support 3rd party integration etc.",
  },
];

export const wasmBenefitsData = [
  {
    id: "Focus on Core Business",
    name: "Focus on Core Business",
    description: `By outsourcing your web application support, you can focus on your core business goals. This allows you to prioritize business growth and development, while leaving the technical details to the experts.`,
    image: wamb1Image,
  },
  {
    id: "Technical Expertise",
    name: "Technical Expertise",
    description: `Expertise can be beneficial in troubleshooting and resolving issues that arise during the use of a web application. This can be especially beneficial if you're working on a complex web app that requires specialized expertise.`,
    image: wamb2Image,
  },
  {
    id: "Reusability of code",
    name: "Reusability of code",
    description: `Web apps can run on
    any device, regardless of the operating system or hardware
    configuration.`,
    image: wamb3Image,
  },
  {
    id: "Reduced downtime",
    name: "Reduced downtime",
    description: `With web application support, businesses can reduce downtime by quickly resolving any technical issues that arise. This helps businesses to stay operational and productive, without being affected by technical issues.`,
    image: wamb4Image,
  },
  {
    id: "Enhanced security",
    name: "Enhanced security",
    description: `Web application support includes security measures, such as firewalls, data backup, and encryption, which help protect against security threats such as hacking, malware, and data breaches.`,
    image: wamb5Image,
  },
  {
    id: "Improved performance",
    name: "Improved performance",
    description: `Web application support can improve the performance of a web application by monitoring its performance metrics, identifying performance bottlenecks, and taking necessary steps to optimize it.`,
    image: wamb6Image,
  },
];

export const wasmWhyThanWeConsultantsData = [
  {
    id: 1,
    Icon: wam7Image,
    isIcon: false,
    name: "24/7 support & Proactive monitoring",
    path: "",
    description:
      "We provide 24/7 support, so you can rest assured that any issues will be resolved quickly and efficiently. We also provide proactive monitoring to detect any potential issues before they become major problems.",
  },
  {
    id: 2,
    Icon: wam8Image,
    isIcon: false,
    name: "Experience & Expertize",
    path: "",
    description:
      "Our team of experts has years of experience in web application development and maintenance, so you can trust us to handle any problem that arises. Our support also includes security measures, such as firewalls, data backup, and encryption, which help protect against security threats such as hacking, malware, and data breaches.",
  },
  {
    id: 3,
    Icon: wam9Image,
    isIcon: false,
    name: "User Training",
    path: "",
    description:
      "In addition, we offer training and resources to help you and your team make the most of your web application and stay up-to-date with the latest developments in the field.",
  },
  {
    id: 4,
    Icon: wam10Image,
    isIcon: false,
    name: "Competitive pricing & flexible plans",
    path: "",
    description:
      "We understand that every business is unique, which is why we offer customized support plans to meet your specific needs. Our team will work with you to identify your requirements and create a support plan that fits your business. Contact us today to learn more about the flexible plans & competitive customized according to your need to achieve your goals.",
  },
];

export const wasmDevelopmentSkillsData = [
  "Knowledge of web servers and hosting platforms",
  "Troubleshooting skills",
  "Proficiency in programming languages",
  "Knowledge of databases",
  "Familiarity with version control systems",
  "Understanding of security best practices",
  "Excellent communication skills",
];

//SEO
export const seoServData = [
  {
    id: 1,
    Icon: seo1Image,
    isIcon: false,
    name: "Keyword research",
    path: "",
    description:
      "Identifying the relevant keywords and phrases that people use to search for your products or services.",
  },
  {
    id: 2,
    Icon: seo2Image,
    isIcon: false,
    name: "On-page & Off-page optimization",
    path: "",
    description: `Optimizing the website's content, structure, and HTML code to make it more search engine-friendly.
      Building high-quality backlinks to your website from other authoritative websites to increase its credibility and authority.
      `,
  },
  {
    id: 3,
    Icon: seo3Image,
    isIcon: false,
    name: "Content creation",
    path: "",
    description:
      "Creating high-quality, relevant, and engaging content that attracts and retains the attention of the target audience.",
  },
  {
    id: 4,
    Icon: seo4Image,
    isIcon: false,
    name: "Local & Technical SEO",
    path: "",
    description: `Optimizing the website's content and structure for local search queries and ensuring that the business's local citations are accurate and consistent.
     Fixing technical issues that affect the website's performance, such as broken links, duplicate content, and slow loading speed.
     `,
  },
  {
    id: 5,
    Icon: seo5Image,
    isIcon: false,
    name: "Reporting and analysis",
    path: "",
    description: `Monitoring and analyzing website traffic, rankings, and other metrics to measure the effectiveness of the SEO campaign and make data-driven decisions.`,
  },
  {
    id: 6,
    Icon: seo6Image,
    isIcon: false,
    name: "Online reputation management",
    path: "",
    description:
      "Managing and improving the online reputation of the business by monitoring and responding to customer reviews and feedback.",
  },
];

export const seoBenefitsData = [
  {
    id: "Increased website traffic",
    name: "Increased website traffic",
    description: `SEO helps to improve the visibility and ranking of your website in search engine results pages (SERPs), which can lead to increased website traffic from potential customers who are searching for products or services like yours.`,
    image: seob1Image,
  },
  {
    id: "Higher brand credibility",
    name: "Higher brand credibility",
    description: `Websites that rank higher in search results are perceived as more trustworthy and credible by users, which can improve the reputation and brand image of your business.`,
    image: seob2Image,
  },
  {
    id: "Improved user experience",
    name: "Improved user experience",
    description: `SEO involves optimizing the website's content, structure, and user experience, which can improve the usability and accessibility of the website for users.`,
    image: seob3Image,
  },
  {
    id: "Competitive advantage",
    name: "Competitive advantage",
    description: `SEO can help businesses stand out from the competition and gain a competitive advantage in their industry by ranking higher in search results and attracting more organic traffic.`,
    image: seob4Image,
  },
  {
    id: "Enhanced security",
    name: "Enhanced security",
    description: `Web application support includes security measures, such as firewalls, data backup, and encryption, which help protect against security threats such as hacking, malware, and data breaches.`,
    image: seob5Image,
  },
  {
    id: "Higher return on investment (ROI)",
    name: "Higher return on investment (ROI)",
    description: `SEO can provide a higher return on investment compared to other forms of online advertising, as it can attract highly targeted and engaged traffic to the website, leading to more leads and sales.`,
    image: seob6Image,
  },
];

export const seoWhyThanWeConsultantsData = [
  {
    id: 1,
    Icon: seo7Image,
    isIcon: false,
    name: "Experience and expertise",
    path: "",
    description:
      "We have a team of experienced and skilled professionals who specialize in SEO techniques and strategies. They have the expertise and knowledge to help businesses improve their online visibility and ranking in search results.",
  },
  {
    id: 3,
    Icon: seo8Image,
    isIcon: false,
    name: "Customized solutions",
    path: "",
    description:
      "We understand that every business is unique, and that's why we offer customized SEO solutions tailored to your specific needs and goals. We conduct a thorough analysis of the website, industry, and target audience to develop an SEO strategy that is effective in achieving the business objectives.",
  },
  {
    id: 4,
    Icon: seo9Image,
    isIcon: false,
    name: "Transparent communication",
    path: "",
    description:
      "We update you regularly and transparently about the progress of the SEO campaign and provides detailed reporting and analysis.",
  },
  {
    id: 5,
    Icon: seo10Image,
    isIcon: false,
    name: "White hat techniques",
    path: "",
    description:
      "We use ethical and white hat SEO techniques that comply with search engine guidelines and best practices.",
  },
  {
    id: 6,
    Icon: seo11Image,
    isIcon: false,
    name: "Results-oriented approach",
    path: "",
    description:
      "We are focused on delivering tangible and measurable results, such as increased website traffic, leads, and sales. This allows businesses to measure the effectiveness of the SEO campaign and make informed decisions about future strategies.",
  },
  {
    id: 7,
    Icon: seo12Image,
    isIcon: false,
    name: "Cost-effectiveness",
    path: "",
    description:
      "Our SEO services provide a good return on investment compared to other forms of online advertising.",
  },
];

export const seoSkillsData = [
  "On-page optimization",
  "Off-page optimization",
  "Technical SEO",
  "Local SEO",
  "Keyword research",
  "Content creation",
];

// Mobile App Design
export const mobileAppDesignServData = [
  {
    id: 1,
    Icon: mad1Image,
    isIcon: false,
    name: "User interface (UI) design",
    path: "",
    description:
      "Designing the visual and interactive elements of the app, such as buttons, menus, screens, and animations. The UI design focuses on creating an intuitive and engaging user experience.",
  },
  {
    id: 2,
    Icon: mad2Image,
    isIcon: false,
    name: "User experience (UX) design",
    path: "",
    description: `Creating a seamless and user-friendly experience for users by optimizing the app's navigation, layout, and interactions. This includes how users navigate through the app, how they interact with the various features, and how they accomplish their goals within the app.
      `,
  },
  {
    id: 3,
    Icon: mad3Image,
    isIcon: false,
    name: "Branding and identity design",
    path: "",
    description:
      "This involves designing the overall branding and identity of the mobile app, including the logo, color scheme, and typography.",
  },
  {
    id: 4,
    Icon: mad4Image,
    isIcon: false,
    name: "Wireframing and prototyping",
    path: "",
    description: `Wireframing involves creating a basic layout of the application, defining the content and functionality that will be included in each screen. Prototyping involves building a working model of the application, allowing for user testing and feedback. This is to test and refine the design before development begins.
     `,
  },
  {
    id: 5,
    Icon: mad5Image,
    isIcon: false,
    name: "App icon, Illustration and animation design",
    path: "",
    description: `App icon design involves designing the icon that will represent the app on the user's device.
    Creating custom illustrations and animations to enhance the visual appeal and engagement of the app.
    `,
  },
  {
    id: 6,
    Icon: mad6Image,
    isIcon: false,
    name: "Responsive design",
    path: "",
    description:
      "This involves designing the app to be responsive and adaptable to different screen sizes and orientations.",
  },
];

export const mobileAppDesignBenefitsData = [
  {
    id: "User Engagement",
    name: "User Engagement",
    description: `A mobile app that is easy to use, visually appealing, and provides a positive experience can increase user engagement and retention.`,
    image: madb1Image,
  },
  {
    id: "Competitive Advantage",
    name: "Competitive Advantage",
    description: `A well-designed mobile app can create a positive impression of the brand and enhance its reputation in the market.`,
    image: madb2Image,
  },
  {
    id: "Increased revenue",
    name: "Increased revenue",
    description: `A mobile app that is easy to use and provides value to the user can encourage repeat usage and promote in-app purchases.`,
    image: madb3Image,
  },
  {
    id: "Brand Recognition",
    name: "Brand Recognition",
    description: `A mobile app that is consistent with the brand's visual identity and messaging can help to build a stronger brand image and increase brand loyalty.`,
    image: madb4Image,
  },
  {
    id: "Data-driven decision making",
    name: "Data-driven decision making",
    description: `A well-designed mobile app can provide valuable insights into user behavior, preferences, and needs. This data can be used to inform future design decisions and improve the overall user experience.`,
    image: madb5Image,
  },
];

export const madWhyThanWeConsultantsData = [
  {
    id: 1,
    Icon: mad7Image,
    isIcon: false,
    name: "Experience and Expertise",
    path: "",
    description:
      "Designers at ThanWe have a deep understanding of the industry, user behavior, and trends, which can help ensure that the app is both user-friendly and innovative. We have a team of experienced designers who have expertise in designing mobile apps across various industries.",
  },
  {
    id: 3,
    Icon: mad8Image,
    isIcon: false,
    name: "Creative and Innovative Design",
    path: "",
    description:
      "We come up with creative and innovative design solutions that stand out from the competition. The goal of mobile design is not to impress, but to provide an experience that solves a problem.",
  },
  {
    id: 4,
    Icon: mad9Image,
    isIcon: false,
    name: "User-Centered Design Approach",
    path: "",
    description:
      "Our mobile app designs are those that are focused on the user's needs and preferences. We conduct user research, create user personas, and test the app with real users to ensure it is intuitive and easy to use.",
  },
  {
    id: 5,
    Icon: mad10Image,
    isIcon: false,
    name: "Collaborative Approach",
    path: "",
    description:
      "Clear communication and collaboration between the company and the design team are essential for the success of any project. We closely with our clients, keeping them informed throughout the design process and incorporating their feedback.",
  },
];

export const madSkillsData = [
  "Knowledge of design patterns & principles",
  "User interface (UI)",
  "User experience (UX)",
  "Prototyping",
  "Graphic design",
];

//Mobile App Maintenance
export const mobileAppMaintenanceServData = [
  {
    id: 1,
    Icon: mam1Image,
    isIcon: false,
    name: "Bug fixing & Third-party Integrations",
    path: "",
    description:
      "Addressing and resolving any issues or bugs encountered by users to maintain app functionality. Maintaining integrations with third-party services such as social media platforms, payment gateways, and analytics tools.",
  },
  {
    id: 2,
    Icon: mam2Image,
    isIcon: false,
    name: "Regular Updates & Security Enhancements",
    path: "",
    description: `Keeping the app up-to-date with the latest software frameworks, security patches, and operating systems to improve app performance and ensure security.
    Implementing security features such as data encryption, secure authentication, and regular security audits to ensure the protection of user data.
    `,
  },
  {
    id: 3,
    Icon: mam3Image,
    isIcon: false,
    name: "User feedback analysis & Content updates",
    path: "",
    description:
      "Incorporating user feedback to identify areas for improvement and enhance the user experience. Regularly updating the app's content to ensure it remains relevant and engaging for users.",
  },
  {
    id: 4,
    Icon: mam4Image,
    isIcon: false,
    name: "Performance Optimization",
    path: "",
    description: `Identifying and resolving performance issues, optimizing code, and improving app speed and responsiveness to ensure a smooth user experience.`,
  },
  {
    id: 5,
    Icon: mam5Image,
    isIcon: false,
    name: "App Store Compliance",
    path: "",
    description: `Ensuring that the app complies with the policies and guidelines of the app store to avoid any issues with app distribution or removal.`,
  },
  {
    id: 6,
    Icon: mam6Image,
    isIcon: false,
    name: "Cloud Management",
    path: "",
    description:
      "Maintaining the app's cloud infrastructure to ensure scalability, performance, and security.",
  },
];

export const mobileAppMaintenanceBenefitsData = [
  {
    id: "Improved functionality",
    name: "Improved functionality",
    description: `Regular maintenance helps to identify and fix any bugs or issues in the app, ensuring that it remains functional and easy to use for your customers.`,
    image: mamb1Image,
  },
  {
    id: "Enhanced security",
    name: "Enhanced security",
    description: `With regular security updates and audits, mobile app maintenance ensures that your app remains secure and protects user data against potential threats.`,
    image: mamb2Image,
  },
  {
    id: "Staying up-to-date with technology trends",
    name: "Staying up-to-date with technology trends",
    description: `Mobile app maintenance ensures that your app stays up-to-date with the latest mobile technology trends, taking advantage of new features and optimizing performance for the latest operating systems and devices.`,
    image: mamb3Image,
  },
  {
    id: "Better user experience",
    name: "Better user experience",
    description: `Incorporating user feedback and implementing improvements based on analytics and usage data enhances the user experience, making your app more engaging and valuable for your customers.`,
    image: mamb4Image,
  },
  {
    id: "Cost savings",
    name: "Cost savings",
    description: `Regular maintenance helps to identify and address issues before they become bigger problems, reducing the cost of fixing them in the long run.`,
    image: mamb5Image,
  },
  {
    id: "Increased customer loyalty",
    name: "Increased customer loyalty",
    description: `By providing a well-maintained app that functions well and incorporates user feedback, you can increase customer satisfaction and loyalty, leading to higher retention rates and increased revenue.`,
    image: mamb6Image,
  },
];

export const mamWhyThanWeConsultantsData = [
  {
    id: 1,
    Icon: mam7Image,
    isIcon: false,
    name: "Experienced Team",
    path: "",
    description:
      "Our team of experienced developers and technicians are well-equipped to handle any maintenance needs your app may require. We have years of experience in the industry and stay up-to-date with the latest mobile technology trends to ensure we can provide the best maintenance services for your app.",
  },
  {
    id: 3,
    Icon: mam8Image,
    isIcon: false,
    name: "Comprehensive Services",
    path: "",
    description:
      " We offer a comprehensive range of mobile app maintenance services, including bug fixing, upgrades, security enhancements, user feedback incorporation, performance optimization, content updates, analytics and reporting, app store management, and third-party integrations.",
  },
  {
    id: 4,
    Icon: mam9Image,
    isIcon: false,
    name: "Cost-Effective",
    path: "",
    description:
      "Our maintenance services are designed to be cost-effective, helping you save money in the long run by identifying and addressing issues before they become bigger problems.",
  },
  {
    id: 5,
    Icon: mam10Image,
    isIcon: false,
    name: "Timely delivery",
    path: "",
    description:
      "We understand the importance of timely maintenance and ensure that all our maintenance services are delivered on time, without compromising on quality.",
  },
  {
    id: 5,
    Icon: mam10Image,
    isIcon: false,
    name: "Customer Satisfaction",
    path: "",
    description:
      "We are committed to providing the highest quality maintenance services and strive to ensure complete customer satisfaction. We value our clients' feedback and work to incorporate their input into our maintenance plan.",
  },
];

export const mamSkillsData = [
  "Programming languages",
  "Operating systems",
  "Mobile app development frameworks",
  "App store management",
  "Third-party integrations",
  "Mobile app testing",
];

// Start of Client Form Data
// SEO Client Form
export const seoClientFormData = [
  {
    question: "Which SEO service(s) do you require?",
    type: "checkbox",
    refer: "seo_services",
    children: [
      {
        id: "40",
        name: "generateContent",
        label: "Generate content",
      },
      {
        id: "41",
        name: "improveGoogleBingRanking",
        label: "Improve Google/Bing ranking",
      },
      {
        id: "42",
        name: "improveMySiteStructure",
        label: "Improve my site structure",
      },
      {
        id: "43",
        name: "increaseMyBacklinks",
        label: "Increase my backlinks",
      },
      {
        id: "44",
        name: "promoteMyWebsite",
        label: "Promote my website",
      },
      {
        id: "45",
        name: "rankForSpecificKeywords",
        label: "Rank for specific keywords",
      },
      {
        id: "46",
        name: "technicalSeo",
        label: "Technical SEO",
      },
      {
        id: "47",
        name: "websiteAudit",
        label: "Website audit",
      },
    ],
  },
  {
    question: "What is the scale of your operation?",
    type: "radio",
    refer: "seo_scale_operations",
    children: [
      {
        id: "48",
        name: "global",
        label: "Global",
      },
      {
        id: "49",
        name: "international",
        label: "International",
      },
      {
        id: "50",
        name: "national",
        label: "National",
      },
      {
        id: "51",
        name: "regional",
        label: "Regional",
      },
      {
        id: "52",
        name: "local",
        label: "Local",
      },
    ]
  },
  {
    question: "How many website visitors do you get each month?",
    type: "radio",
    refer: "seo_website_visitors",
    children: [
      {
        id: "53",
        name: "lessThan500",
        label: "Less than 500",
      },
      {
        id: "54",
        name: "500To999",
        label: "500 - 999",
      },
      {
        id: "55",
        name: "1000To4999",
        label: "1,000 - 4,999",
      },
      {
        id: "56",
        name: "5000To9999",
        label: "5,000 - 9,999",
      },
      {
        id: "57",
        name: "10000To49999",
        label: "10,000 - 49,999",
      },
      {
        id: "58",
        name: "50000Ormore",
        label: "50,000 or more",
      },
      {
        id: "59",
        name: "iDontHaveAWebsiteYet",
        label: "I don't have a website yet, I'm not sure",
      },
    ]
  },
  {
    question: "What is your approximate monthly budget for SEO?",
    type: "radio",
    refer: "seo_monthly_budget",
    children: [
      {
        id: "60",
        name: "lessThanTenThousand",
        label: "Less than ₹10k",
      },
      {
        id: "61",
        name: "tenTofourtyNineThousand",
        label: "₹10k to ₹49k",
      },
      {
        id: "62",
        name: "fiftyToNinetyNineThousand",
        label: "₹50k to ₹99k",
      },
      {
        id: "63",
        name: "oneLakhOrMore",
        label: "₹1 Lakh or more",
      },
      {
        id: "64",
        name: "imNotSure",
        label: "I'm not sure",
      },
      {
        id: "65",
        name: "iWouldLikeToDiscussWithPro",
        label: "I'd like to discuss this with the pro",
      },
      {
        id: "66",
        name: "other",
        label: "Other",
      },
    ]
  },
  {
    question: "What is the type of your company?",
    type: "radio",
    refer: "seo_type_of_company",
    children: [
      {
        id: "67",
        name: "Personal_individual",
        label: "Personal/individual",
      },
      {
        id: "68",
        name: "nonprofitorganisation",
        label: "Non-profit organisation",
      },
      {
        id: "69",
        name: "b2b_business",
        label: "B2B business",
      },
      {
        id: "70",
        name: "b2c_business",
        label: "B2C business",
      },
      {
        id: "71",
        name: "ecommerce_business",
        label: "eCommerce business",
      },
      {
        id: "72",
        name: "other",
        label: "Other",
      },
    ]
  },
  {
    question: "What industry do you operate in?",
    type: "radio",
    refer: "seo_industry_operate",
    children: [
      {
        id: "73",
        name: "bankingFinanceServices",
        label: "Banking, financial services and insurance(BFSI)",
      },
      {
        id: "74",
        name: "education",
        label: "Education",
      },
      {
        id: "75",
        name: "entertainment",
        label: "Entertainment",
      },
      {
        id: "76",
        name: "fashionAndFurnishing",
        label: "Fashion and furnishing",
      },
      {
        id: "77",
        name: "fmcgAndDurableGoods",
        label: "FMCG and durable goods",
      },
      {
        id: "7",
        name: "foodAndHospitality",
        label: "Food and hospitality",
      },
      {
        id: "78",
        name: "healthAndPharmaceuticals",
        label: "Health and pharmaceuticals",
      },
      {
        id: "79",
        name: "industrialEquipment",
        label: "Industrial equipment",
      },
      {
        id: "80",
        name: "retail",
        label: "Retail",
      },
    ]
  },
  {
    question: "When do you want the work to begin?",
    type: "radio",
    refer: "seo_work_begin",
    children: [
      {
        id: "81",
        name: "asap",
        label: "ASAP",
      },
      {
        id: "82",
        name: "withInAWeek",
        label: "Within a week",
      },
      {
        id: "83",
        name: "withIn2Weeks",
        label: "Within 2 weeks",
      },
      {
        id: "84",
        name: "withInAMonth",
        label: "Within a month",
      },
      {
        id: "85",
        name: "withInTheNext2To3Months",
        label: "Within the next 2-3 months",
      },
      {
        id: "86",
        name: "other",
        label: "Other",
      },
    ]
  },
]

//React Native App Development Client Form
export const rndClientFormDesignMocksUpsData = [
  {
    id: "rnddesignwork_yes",
    name: "yes",
    label: "Yes",
  },
  {
    id: "rnddesignwork_no",
    name: "no",
    label: "No",
  },
];

export const rndClientFormExistingAppData = [
  {
    id: "rndea_yes",
    name: "rndea_yes",
    label: "Yes",
  },
  {
    id: "rndea_no",
    name: "rndea_no",
    label: "No",
  },
];

export const rndClientFormWireframesData = [
  {
    id: "rndwireframes_yes",
    name: "rndwireframes_yes",
    label: "Yes",
  },
  {
    id: "rndwireframes_no",
    name: "rndwireframes_no",
    label: "No",
  },
];

export const rndClientFormReferencesAppData = [
  {
    id: "rndReferencesApp_yes",
    name: "rndReferencesApp_yes",
    label: "Yes",
  },
  {
    id: "rndReferencesApp_no",
    name: "rndReferencesApp_no",
    label: "No",
  },
];

export const rndClientFormExistingItunesData = [
  {
    id: "rndItunes_yes",
    name: "rndItunes_yes",
    label: "Yes",
  },
  {
    id: "rndItunes_no",
    name: "rndItunes_no",
    label: "No",
  },
];

export const rndClientFormFunctionalitiesData = [
  {
    id: "onboarding_process",
    name: "onboarding_process",
    label: "Onboarding Process",
  },
  {
    id: "multifactor_authentication",
    name: "multifactor_authentication",
    label: "Multifactor Authentication",
  },
  {
    id: "personalization",
    name: "personalization",
    label: "Personalization",
  },
  {
    id: "intuitive_navigation",
    name: "intuitive_navigation",
    label: "Intuitive Navigation",
  },
  {
    id: "push_notifications",
    name: "push_notifications",
    label: "Push Notifications",
  },
  {
    id: "hq_image_resolution",
    name: "hq_image_resolution",
    label: "High-quality Image Resolution",
  },
  {
    id: "search_feature",
    name: "search_feature",
    label: "Search Feature",
  },
  {
    id: "text_chat",
    name: "text_chat",
    label: "Text Chat",
  },
  {
    id: "voice_chat",
    name: "voice_chat",
    label: "Voice Chat",
  },
  {
    id: "video_chat",
    name: "video_chat",
    label: "Video chat",
  },
  {
    id: "music_video_player",
    name: "music_video_player",
    label: "Music/Video player",
  },
  {
    id: "social_media_integration",
    name: "social_media_integration",
    label: "Social Media Integration",
  },
  {
    id: "user_feedback",
    name: "user_feedback",
    label: "User Feedback",
  },
  {
    id: "payment_gateway_integration",
    name: "payment_gateway_integration",
    label: "Payment gateway integration",
  },
  {
    id: "third_party_api_services",
    name: "third_party_api_services",
    label: "Third party API services",
  },
  {
    id: "cameraIntegration",
    name: "cameraIntegration",
    label: "Camera Integration",
  },
  {
    id: "gps_location_tracking",
    name: "gps_location_tracking",
    label: "GPS/Location Tracking",
  },
  {
    id: "file_manager",
    name: "file_manager",
    label: "File manager",
  },
  {
    id: "photo_gallery",
    name: "photo_gallery",
    label: "Photo Gallery",
  },
  {
    id: "web_browser",
    name: "web_browser",
    label: "Web Browser",
  },
  {
    id: "phone_call_integration",
    name: "phone_call_integration",
    label: "Phone Call Integration",
  },
  {
    id: "qr_barcode_integration",
    name: "qr_barcode_integration",
    label: "Qr code/barcode Integration",
  },
];

export const rndClientFormIsCMSData = [
  {
    id: "rndCMS_yes",
    name: "rndCMS_yes",
    label: "Yes",
  },
  {
    id: "rndCMS_no",
    name: "rndCMS_no",
    label: "No",
  },
];

export const rndClientFormMultilingualAppData = [
  {
    id: "rndMultilingual_yes",
    name: "rndMultilingual_yes",
    label: "Yes",
  },
  {
    id: "rndMultilingual_no",
    name: "rndMultilingual_no",
    label: "No",
  },
];

export const rndClientFormCompanionWebsiteData = [
  {
    id: "rndCompanionWebsite_yes",
    name: "rndCompanionWebsite_yes",
    label: "Yes",
  },
  {
    id: "rndCompanionWebsite_no",
    name: "rndCompanionWebsite_no",
    label: "No",
  },
];

export const rndClientFormOrientationData = [
  {
    id: "rndOrientation_portrait",
    name: "portrait",
    label: "Portrait",
  },
  {
    id: "rndCompanionWebsite_landscape",
    name: "landscape",
    label: "Landscape",
  },
  {
    id: "rndCompanionWebsite_both",
    name: "both",
    label: "Both",
  },
];

export const rndClientFormFree_PaidData = [
  {
    id: "rndfree_app",
    name: "free_app",
    label: "Free",
  },
  {
    id: "rndpaid_app",
    name: "paid_app",
    label: "Paid",
  },
];

// web app developmnet Client Form
export const wadClientFormIsCMSData = [
  {
    id: "wadCMS_yes",
    name: "yes",
    label: "Yes",
  },
  {
    id: "wadCMS_no",
    name: "no",
    label: "No",
  },
];

export const wadClientFormDesignMocksUpsData = [
  {
    id: "waddesignwork_yes",
    name: "yes",
    label: "Yes, we want you to do all the design work",
  },
  {
    id: "waddesignwork_no",
    name: "no",
    label: "No, we have a designer who will provide you the designs we want",
  },
];

// Mobile App Design Client Form
export const madClientFormData = [
  {
    question: "What device(s) is the application expected to work on?",
    type: "checkbox",
    refer: "mad_devices_to_work_on",
    children: [
      {
        id: "mad_cf_iphone",
        name: "mad_cf_iphone",
        label: "IPHONE",
      },
      {
        id: "mad_cf_ipad",
        name: "mad_cf_ipad",
        label: "IPAD",
      },
      {
        id: "android_phone",
        name: "android_phone",
        label: "Android Phone",
      },
      {
        id: "android_tablet",
        name: "android_tablet",
        label: "Android Tablet",
      },
      {
        id: "mad_cf_other",
        name: "mad_cf_other",
        label: "Other",
      },
    ],
  },
  {
    question: "Do you want to have a separate layout for the tablet and phone?",
    type: "radio",
    refer: "mad_cf_layout",
    children: [
      {
        id: "mad_cf_yes",
        name: "mad_cf_yes",
        label: "Yes",
      },
      {
        id: "mad_cf_no",
        name: "mad_cf_no",
        label: "No, App will run in compatibility mode",
      },
      {
        id: "mad_cf_notsure",
        name: "mad_cf_notsure",
        label: "Not Sure",
      },
    ],
  },
  {
    question: "Do you want your app portrait or landscape or both?",
    type: "radio",
    refer: "mad_cf_orientation",
    children: [
      {
        id: "mad_cf_portrait",
        name: "mad_cf_portrait",
        label: "Portrait",
      },
      {
        id: "mad_cf_landscape",
        name: "mad_cf_landscape",
        label: "Landscape",
      },
      {
        id: "mad_cf_both",
        name: "mad_cf_both",
        label: "Both",
      },
    ],
  },
  {
    question:
      "Do you have an app icon or logo already? If yes, please attach in the attachments section",
    type: "radio",
    refer: "mad_cf_app_icon",
    children: [
      {
        id: "mad_cf_logo_yes",
        name: "mad_cf_logo_yes",
        label: "Yes",
      },
      {
        id: "mad_cf_logo_no",
        name: "mad_cf_logo_no",
        label: "No",
      },
    ],
  },
  {
    question:
      "What three words should describe the tone of your site? (e.g. conservative / friendly / fun / sophisticated /minimalist / exciting, etc.)",
    type: "textbox",
    name: "mad_cf_tone_of_your_site",
  },
  {
    question:
      "Do you have any opinions specifically for color, theme and style?",
    type: "textbox",
    name: "mad_cf_theme_of_your_site",
  },
  {
    question: "Are there any colors that should be avoided?",
    type: "textbox",
    name: "mac_cf_colors_avoided",
  },
  {
    question:
      "Do you have any UI/UX layout designs, prototypes or documentation?",
    type: "textbox",
    name: "mac_cf_layout_designs",
  },
  {
    question:
      "Please list two websites that you like and would like to use as inspiration for your own. Describe what you like about them.",
    type: "textbox",
    name: "mac_cf_inspiration_websites",
  },
];

// Mobile App Maintenance Client Form
export const mamClientFormData = [
  {
    question:
      "Can you tell us a little bit about your mobile app? What does it do, and what is its purpose?",
    type: "textbox",
    name: "mam_cf_about_your_mobileapp",
  },
  {
    question:
      "Are you experiencing any specific issues with your mobile app, or are you looking for general maintenance and support?",
    type: "textbox",
    name: "mam_cf_issues_mobileapp",
  },
  {
    question:
      "What level of support are you looking for? For example, do you need 24/7 support, or are regular business hours sufficient?",
    type: "textbox",
    name: "mam_cf_support_mobileapp",
  },
  {
    question:
      "What is your budget for mobile app support and maintenance services?",
    type: "textbox",
    name: "mam_cf_budget_mobileapp",
  },
  {
    question: "What is your timeline for getting started with these services?",
    type: "textbox",
    name: "mam_cf_timeline_mobileapp",
  },
  {
    question:
      "Are there any specific features or functionalities you are looking to add or improve in your mobile app?",
    type: "textbox",
    name: "mam_cf_specific_features_mobileapp",
  },
  {
    question:
      "We provide the following services as a part of the Mobile App Maintenance. Please choose",
    type: "checkbox",
    refer: "mam_cf_services",
    children: [
      {
        id: "mam_cf_app_monitoring_services",
        name: "mam_cf_app_monitoring_services",
        label: "App Monitoring Services",
        isSubChild: true,
        subChild: [
          "App Crash Monitoring",
          "App Store Maintenance",
          "App Feedback Monitoring",
        ],
      },
      {
        id: "mam_cf_app_upgrade_services",
        name: "mam_cf_app_upgrade_services",
        label: "App Upgrade Services",
        isSubChild: true,
        subChild: [
          "Bug Tracking, Debugging and Fixing",
          "Mobile app performance optimization",
          "Improve the app interfaces and interactions",
          "Minor code & UI changes",
        ],
      },
      {
        id: "mam_cf_services",
        name: "mam_cf_app_services",
        label: "App Maintenance and Enhancement Services",
        isSubChild: true,
        subChild: [
          "UI/UX Enhancement",
          "Performance enhancement",
          "Migrating applications to different platforms",
          "Security updates and reviews",
          "Incorporating latest trends",
          "Continuous testing",
        ],
      },
      {
        id: "mam_cf_app_support_maintenance_services",
        name: "mam_cf_app_support_maintenance_services",
        label: "App Support, Maintenance & Optimization Services",
        isSubChild: true,
        subChild: [
          "Responding to customer queries",
          "Deploying the apps to app stores/enterprise stores",
          "SEO Audit & Performance Optimization",
          "Third party integration update reviews",
        ],
      },
    ],
  },
];
