import React from "react";
import { PathAnimationLoader } from "./Loaders/PathAnimationLoader";

function Fallbacksuspense({ isLogoVisible = false }) {
  return (
    // <div className="flex flex-col min-h-screen justify-center items-center gap-10">
    //   <div className="flex flex-col  justify-center items-center">
    //     <Triangle
    //       height="80"
    //       width="80"
    //       color="#4fa94d"
    //       ariaLabel="triangle-loading"
    //       wrapperStyle={{}}
    //       wrapperClassName=""
    //       visible={true}
    //     />
    //     <p>loading please wait...</p>
    //   </div>
    //   {isLogoVisible && <div >
    //     <img src={logo4Image} loading="lazy" alt="logo" className="lg:w-32 lg:h-32 w-32 h-20"/>
    //   </div>}
    // </div>
    // <FluidAnimationLoader />
    <>
      <PathAnimationLoader />
    </>
  );
}

export default Fallbacksuspense;
