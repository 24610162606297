import React from "react";

function ChatBotCheckBoxWithAdditionalInput({
  question,
  setOptions,
  option,
  additionalQuestion,
  setAdditionalState,
  onClick,
  data
}) {
  return (
    <div className="flex flex-col">
      <p>{question}</p>
      {data.map((item, index) => {
        return (
          <div key={item.name} className="flex flex-row items-center ">
            <input
              onChange={(e) => {
                // add to list
                if (e.target.checked) {
                  setOptions([...option, item.label]);
                } else {
                  // remove from list
                  setOptions(option.filter((o) => o !== item.label));
                }
              }}
              value={option}
              className="mx-2"
              type="checkbox"
            />
            <p>{item.label}</p>
          </div>
        );
      })}
      <div className="flex flex-col gap-2">
        <p>{additionalQuestion}</p>
        <input
          type="text"
          onChange={(e) => {
            setAdditionalState(e.target.value);
          }}
          className="border border-border rounded-sm"
        />
        <button
          aria-label="Next"
          className={"bg-sand px-2 py-2 rounded-md"}
          type="submit"
          onClick={onClick}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ChatBotCheckBoxWithAdditionalInput;
