import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { clientFormAction, contactScreenAction } from "../Redux/Actions/ClientFormAction";
import {
  FunctionalityData,
  GoalsData,
  StandardPagesData,
  additionalServicesData,
  seoClientFormData,
  wasmServicesData,
  rndClientFormDesignMocksUpsData,
  rndClientFormExistingAppData,
  rndClientFormWireframesData,
  madClientFormData,
  rndClientFormReferencesAppData,
  rndClientFormExistingItunesData,
  rndClientFormFunctionalitiesData,
  rndClientFormMultilingualAppData,
  rndClientFormIsCMSData,
  rndClientFormCompanionWebsiteData,
  rndClientFormOrientationData,
  rndClientFormFree_PaidData,
  mamClientFormData,
} from "./CustomData";
import ChatBotRadioInput from "../Components/ChatBotInputs/ChatBotRadioInput";
import ChatBotCheckBox from "../Components/ChatBotInputs/ChatBotCheckBox";
import ChatBotCheckBoxWithAdditionalInput from "../Components/ChatBotInputs/ChatBotCheckBoxWithAdditionalInput";

const id = Math.floor(Math.random() * 1000000000);
const workorder_id = Math.floor(Math.random() * 1000000000);

const DetailsComponent = ({ steps, triggerNextStep }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { name, email, phonenumber, company_name } = steps;
  const link = `https://thanwe-website.web.app/thanweconsultants/clientRequirementForm/${workorder_id}`;

  const data = {
    email: email.value,
    phonenumber: "+91" + phonenumber.value,
    company: company_name.value,
    requirements: [steps.looking_for_options.value],
    name: name.value,
    message: "",
  };

  useEffect(() => {
    sendDetails();
  }, []);

  //Note : uncomment this line in production
  const sendDetails = async () => {
    await dispatch(
      contactScreenAction(data, setIsLoading, id, workorder_id, link)
    );
    triggerNextStep({ trigger: "wad_yes_no" });
  };

  return (
    <div>
      <p>
        Sounds good.Are you willing to answer few more questions on your
        requirements ?
      </p>
      <p>
        Alternatively you can provide your details in this{" "}
        <a
          href={link}
          target="_blank"
          className="text-water underline"
          rel="noreferrer"
        >
          link
        </a>{" "}
        or to us when we contact you.
      </p>
    </div>
  );
};

const GoalOptions = ({ steps, triggerNextStep }) => {
  const [goalOption, setGoalOptions] = useState([]);
  const [goal_other, setOther] = useState("");

  return (
    <>
      <ChatBotCheckBoxWithAdditionalInput
        question="What are your goals for your website?"
        setOptions={setGoalOptions}
        option={goalOption}
        additionalQuestion="If any other, type and press next"
        setAdditionalState={setOther}
        onClick={() =>
          triggerNextStep({
            value: { goals: goalOption, goal_other: goal_other },
            trigger: "standard_pages",
          })
        }
        data={GoalsData}
      />
    </>
  );
};

const StandardPagesOptions = ({ steps, triggerNextStep }) => {
  const [standardOption, setStandardOptions] = useState([]);
  const [additional_pages, setAdditionalPages] = useState("");

  return (
    <>
      <ChatBotCheckBoxWithAdditionalInput
        question="Now let us see what pages do you require in the website."
        setOptions={setStandardOptions}
        option={standardOption}
        additionalQuestion="If any other Additional pages, type and press next"
        setAdditionalState={setAdditionalPages}
        onClick={() =>
          triggerNextStep({
            value: {
              standard_pages: standardOption,
              additional_pages: additional_pages,
            },
            trigger: "functionality_required",
          })
        }
        data={StandardPagesData}
      />
    </>
  );
};

const FunctionalitesOptions = ({ steps, triggerNextStep }) => {
  const [functionalityOption, setFunctionalityOptions] = useState([]);
  const [other_functionalities, setOtherFunctionalities] = useState("");

  return (
    <>
      <ChatBotCheckBoxWithAdditionalInput
        question="Functionality can be integrated into your website using modules /
      plugins. Please indicate which modules you will require."
        setOptions={setFunctionalityOptions}
        option={functionalityOption}
        additionalQuestion="If any other Additional functionality required type and press next"
        setAdditionalState={setOtherFunctionalities}
        onClick={() =>
          triggerNextStep({
            value: {
              functionalities: functionalityOption,
              other_functionalities: other_functionalities,
            },
            trigger: "functionality_required_thanks",
          })
        }
        data={FunctionalityData}
      />
    </>
  );
};

const AdditionalServicesOptions = ({ steps, triggerNextStep }) => {
  const [additionalServicesOption, setAdditionalServicesOptions] = useState([]);
  return (
    <>
      <ChatBotCheckBox
        question="Below are such services, do let us know if you are interested in any of
        them"
        setOptions={setAdditionalServicesOptions}
        option={additionalServicesOption}
        onClick={() =>
          triggerNextStep({
            value: {
              additional_services: additionalServicesOption,
            },
            trigger: "final_question",
          })
        }
        data={additionalServicesData}
      />
    </>
  );
};

const InspirationAnswerBasedQuestion = ({ steps, previousStep }) => {
  return (
    <div>
      {previousStep?.value?.toLowerCase() === "no" ? (
        <div>
          <p>No problem, lets create something unique and beautiful.</p>
        </div>
      ) : (
        <p>
          Yes, that’s a proper website to take inspiration from. We will make
          sure to keep that in mind while designing.
        </p>
      )}
    </div>
  );
};

const InspirationWebsiteQuestion = () => {
  return (
    <div>
      <p>Sure, we will keep that in mind.</p>
      <p>
        Some one said “If I could say it in words there would be no reason for
        art"
      </p>
      <p>
        So, if there is any website which you have in mind for us to take
        inspiration from to satisfy your requirement, please do let us know.
        Type No if there isn'nt any.
      </p>
    </div>
  );
};

const triggerSpecificId = (id) => {
  switch (id) {
    case "Search Engine Optimization":
      return "search_engine_optimization";
    case "Web App Support & Maintenance":
      return "web_app_support_maintenance";
    case "React Native App Development":
      return "react_native_app_development";
    case "Mobile App Design":
      return "mobile_app_design";
    case "Mobile App Maintenance":
      return "mobile_app_maintenance";
    default:
      return "wad_your_requirement";
  }
};

// SEO START
const SEOServicesOptions = ({ steps, triggerNextStep }) => {
  const [seoServicesOption, setSeoServicesOptions] = useState([]);
  const seoServicesData = seoClientFormData.filter(
    (x) => x.refer === "seo_services"
  )[0]?.children;
  return (
    <>
      <ChatBotCheckBox
        question="Which SEO service(s) do you require?"
        setOptions={setSeoServicesOptions}
        option={seoServicesOption}
        onClick={() =>
          triggerNextStep({
            value: {
              seo_services: seoServicesOption,
            },
            trigger: "seo_scale_operation",
          })
        }
        data={seoServicesData}
      />
    </>
  );
};

const SEOScaleOperations = ({ steps, triggerNextStep }) => {
  const [seoScaleOperation, setSeoScaleOperation] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const seoScaleOperationData = seoClientFormData.filter(
    (x) => x.refer === "seo_scale_operations"
  )[0]?.children;
  return (
    <>
      <ChatBotRadioInput
        question="What is the scale of your operation?"
        data={seoScaleOperationData}
        value={seoScaleOperation}
        isCurrentChecked={isCurrentChecked}
        setIsCurrentChecked={setIsCurrentChecked}
        setData={setSeoScaleOperation}
        onClick={() =>
          triggerNextStep({
            value: {
              seo_scale_operation: seoScaleOperation,
            },
            trigger: "seo_website_visitors",
          })
        }
      />
    </>
  );
};

const SEOWebsiteVisitors = ({ steps, triggerNextStep }) => {
  const [seoWebsiteVisitors, setWebsiteVisitors] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const seoWebsiteVistorsData = seoClientFormData.filter(
    (x) => x.refer === "seo_website_visitors"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="How many website visitors do you get each month?"
      data={seoWebsiteVistorsData}
      value={seoWebsiteVisitors}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setWebsiteVisitors}
      onClick={() =>
        triggerNextStep({
          value: {
            seo_website_visitors: seoWebsiteVisitors,
          },
          trigger: "seo_monthly_budget",
        })
      }
    />
  );
};

const SEOMonthlyBudget = ({ steps, triggerNextStep }) => {
  const [seoMonthlyBudget, setSeoMonthlyBudget] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const seoMonthlyBudgetData = seoClientFormData.filter(
    (x) => x.refer === "seo_monthly_budget"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="What is your approximate monthly budget for SEO?"
      data={seoMonthlyBudgetData}
      value={seoMonthlyBudget}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setSeoMonthlyBudget}
      onClick={() =>
        triggerNextStep({
          value: {
            seo_monthly_budget: seoMonthlyBudget,
          },
          trigger: "seo_personal_individual",
        })
      }
    />
  );
};

const SEOPersonalIndividual = ({ steps, triggerNextStep }) => {
  const [seoBusinessData, setSeoBusinessData] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const seoPersonalIndividualBusinessData = seoClientFormData.filter(
    (x) => x.refer === "seo_type_of_company"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="What is the type of your company?"
      data={seoPersonalIndividualBusinessData}
      value={seoBusinessData}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setSeoBusinessData}
      onClick={() =>
        triggerNextStep({
          value: {
            seo_business_data: seoBusinessData,
          },
          trigger: "seo_what_industry_operate",
        })
      }
    />
  );
};

const SEOWhatIndustryDoYouOperate = ({ steps, triggerNextStep }) => {
  const [seoWhatIndustry, setWhatIndustry] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const seoWhatIndustryOperateData = seoClientFormData.filter(
    (x) => x.refer === "seo_industry_operate"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="What industry do you operate in?"
      data={seoWhatIndustryOperateData}
      value={seoWhatIndustry}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setWhatIndustry}
      onClick={() =>
        triggerNextStep({
          value: {
            seo_whatIndustry: seoWhatIndustry,
          },
          trigger: "seo_work_begin",
        })
      }
    />
  );
};

const SEOWorkBegin = ({ steps, triggerNextStep }) => {
  const [seoWorkBegin, setSeoWorkbegin] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const seoWorkBeginData = seoClientFormData.filter(
    (x) => x.refer === "seo_work_begin"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="When do you want the work to begin?"
      data={seoWorkBeginData}
      value={seoWorkBegin}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setSeoWorkbegin}
      onClick={() =>
        triggerNextStep({
          value: {
            seo_work_begin: seoWorkBegin,
          },
          trigger: "q-submit",
        })
      }
    />
  );
};

// SEO END

// WASM Start
const WasmServices = ({ steps, triggerNextStep }) => {
  const [wasmService, setWasmService] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  // const seoWorkBeginData = seoClientFormData.filter(
  //   (x) => x.refer === "seo_work_begin"
  // )[0]?.children;
  return (
    <ChatBotRadioInput
      question="We provide the following services as a part of the Web App Support & Maintenance. Please choose"
      data={wasmServicesData}
      value={wasmService}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setWasmService}
      onClick={() =>
        triggerNextStep({
          value: {
            seo_work_begin: wasmService,
          },
          trigger: "q-submit",
        })
      }
    />
  );
};
// WASM END

// RND Start
const RndDesignWorks = ({ steps, triggerNextStep }) => {
  const [rndDesignWork, setRndDesignWork] = useState();
  const [isCurrentCheckedDesingWork, setIsCurrentCheckedDesignWork] = useState({
    id: "",
  });

  return (
    <ChatBotRadioInput
      question="Do you want us to do all the design?"
      data={rndClientFormDesignMocksUpsData}
      value={rndDesignWork}
      isCurrentChecked={isCurrentCheckedDesingWork}
      setIsCurrentChecked={setIsCurrentCheckedDesignWork}
      setData={setRndDesignWork}
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_design_works: rndDesignWork,
          },
          trigger: "rnd_existing_app",
        })
      }
    />
  );
};

const RndExistingApp = ({ steps, triggerNextStep }) => {
  const [rndExistingApp, setRndExistingApp] = useState();
  const [isCurrentCheckedExistApp, setIsCurrentCheckedExistApp] = useState({
    id: "",
  });
  const [provideLink, setProvideLink] = useState("");

  useEffect(() => {
    if (rndExistingApp === "No") {
      setProvideLink("");
    }
  }, [rndExistingApp]);

  return (
    <>
      <ChatBotRadioInput
        question="Do you have an existing app. If yes, please provide the link."
        data={rndClientFormExistingAppData}
        value={rndExistingApp}
        isCurrentChecked={isCurrentCheckedExistApp}
        setIsCurrentChecked={setIsCurrentCheckedExistApp}
        setData={setRndExistingApp}
        inputBox={
          rndExistingApp === "Yes" && (
            <input
              className="w-full text-sm mt-2 p-5 border border-grey-800 rounded text-main"
              type="text"
              value={provideLink}
              onChange={(e) => setProvideLink(e.target.value)}
            />
          )
        }
        onClick={() =>
          triggerNextStep({
            value: {
              rnd_existing_app: rndExistingApp,
            },
            trigger: "rnd_wireframes",
          })
        }
      />
    </>
  );
};

const RndWireFrames = ({ steps, triggerNextStep }) => {
  const [rndWireframes, setRndWireframes] = useState();
  const [isCurrentCheckedWire, setIsCurrentCheckedWire] = useState({ id: "" });
  return (
    <ChatBotRadioInput
      question="Do you have wireframes or app designs (PSD/AI files)? If so please
      zip and attach the files in the attachment section."
      data={rndClientFormWireframesData}
      value={rndWireframes}
      isCurrentChecked={isCurrentCheckedWire}
      setIsCurrentChecked={setIsCurrentCheckedWire}
      setData={setRndWireframes}
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_wireframes: rndWireframes,
          },
          trigger: "rnd_reference_app",
        })
      }
    />
  );
};

const RndReferenceApp = ({ steps, triggerNextStep }) => {
  const [rndReferenceApp, setRndReferenceApp] = useState();
  const [isCurrentCheckedWire, setIsCurrentCheckedWire] = useState({ id: "" });
  const [other, setOther] = useState("");

  useEffect(() => {
    if (rndReferenceApp === "No") {
      setOther("");
    }
  }, [rndReferenceApp]);
  return (
    <ChatBotRadioInput
      question="Do you have any reference app(s) which works similar to your
      requirement? Please list the ones you found closest. [This can be
      even your competitor apps]."
      data={rndClientFormReferencesAppData}
      value={rndReferenceApp}
      isCurrentChecked={isCurrentCheckedWire}
      setIsCurrentChecked={setIsCurrentCheckedWire}
      setData={setRndReferenceApp}
      inputBox={
        rndReferenceApp === "Yes" && (
          <input
            className="w-full text-sm mt-2 p-5 border border-grey-800 rounded text-main"
            type="text"
            value={other}
            onChange={(e) => setOther(e.target.value)}
          />
        )
      }
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_reference_app: rndReferenceApp,
          },
          trigger: "rnd_existings_itunes_googleplay",
        })
      }
    />
  );
};

const RndExitingItunesGplay = ({ steps, triggerNextStep }) => {
  const [rndExitingItunesGplay, setRndExitingItunesGplay] = useState();
  const [isCurrentCheckedWire, setIsCurrentCheckedWire] = useState({ id: "" });
  const [otherItunesGplay, setItunesGplayOther] = useState("");

  useEffect(() => {
    if (rndExitingItunesGplay === "No") {
      setItunesGplayOther("");
    }
  }, [rndExitingItunesGplay]);
  return (
    <ChatBotRadioInput
      question="Do you have an existing iTunes/ Google Play account for app
      deployment? If yes please provide the details below else we will
      take care."
      data={rndClientFormExistingItunesData}
      value={rndExitingItunesGplay}
      isCurrentChecked={isCurrentCheckedWire}
      setIsCurrentChecked={setIsCurrentCheckedWire}
      setData={setRndExitingItunesGplay}
      inputBox={
        rndExitingItunesGplay === "Yes" && (
          <input
            className="w-full text-sm mt-2 p-5 border border-grey-800 rounded text-main"
            type="text"
            value={otherItunesGplay}
            onChange={(e) => setItunesGplayOther(e.target.value)}
          />
        )
      }
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_existings_itunes_googleplay: rndExitingItunesGplay,
          },
          trigger: "rnd_functionalities",
        })
      }
    />
  );
};

const RndFunctionalitiesData = ({ steps, triggerNextStep }) => {
  const [rndFunctionalities, setRndFunctionalities] = useState([]);
  const [rndFunctionalitiesOther, setRndFunctionalitiesOther] = useState("");
  return (
    <>
      <ChatBotCheckBox
        question="Functionalities"
        setOptions={setRndFunctionalities}
        option={rndFunctionalities}
        inputBox={
          <input
            className="w-full text-sm mt-2 px-5 border border-grey-800 rounded text-main"
            type="text"
            value={rndFunctionalitiesOther}
            onChange={(e) => setRndFunctionalitiesOther(e.target.value)}
          />
        }
        onClick={() =>
          triggerNextStep({
            value: {
              rnd_functionalities: rndFunctionalities,
            },
            trigger: "rnd_cms",
          })
        }
        data={rndClientFormFunctionalitiesData}
      />
    </>
  );
};

const RndCms = ({ steps, triggerNextStep }) => {
  const [rndCms, setRndCms] = useState();
  const [isCurrentCheckedWire, setIsCurrentCheckedWire] = useState({ id: "" });
  const [otherCms, setOtherCms] = useState("");

  useEffect(() => {
    if (rndCms === "No") {
      setOtherCms("");
    }
  }, [rndCms]);
  return (
    <ChatBotRadioInput
      question="Would you like to have a CMS for the app? (CMS is a system which
        lets you add content and make day-to-day updates on your mobile app)"
      data={rndClientFormIsCMSData}
      value={rndCms}
      isCurrentChecked={isCurrentCheckedWire}
      setIsCurrentChecked={setIsCurrentCheckedWire}
      setData={setRndCms}
      inputBox={
        rndCms === "Yes" && (
          <input
            className="w-full text-sm mt-2 p-5 border border-grey-800 rounded text-main"
            type="text"
            value={otherCms}
            onChange={(e) => setOtherCms(e.target.value)}
          />
        )
      }
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_cms: rndCms,
          },
          trigger: "rnd_multilingual",
        })
      }
    />
  );
};

const RndMultilingual = ({ steps, triggerNextStep }) => {
  const [rndMultilingual, setRndMultilingual] = useState();
  const [isCurrentCheckedWire, setIsCurrentCheckedWire] = useState({ id: "" });
  const [otherMultilingual, setOtherMultilingual] = useState("");

  useEffect(() => {
    if (rndMultilingual === "No") {
      setOtherMultilingual("");
    }
  }, [rndMultilingual]);
  return (
    <ChatBotRadioInput
      question="Are you considering the app to be multilingual? If yes, please
      specify the languages."
      data={rndClientFormMultilingualAppData}
      value={rndMultilingual}
      isCurrentChecked={isCurrentCheckedWire}
      setIsCurrentChecked={setIsCurrentCheckedWire}
      setData={setRndMultilingual}
      inputBox={
        rndMultilingual === "Yes" && (
          <input
            className="w-full text-sm mt-2 p-5 border border-grey-800 rounded text-main"
            type="text"
            value={otherMultilingual}
            onChange={(e) => setOtherMultilingual(e.target.value)}
          />
        )
      }
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_multilingual: rndMultilingual,
          },
          trigger: "rnd_companion_website",
        })
      }
    />
  );
};

const RndCompanionWebsite = ({ steps, triggerNextStep }) => {
  const [rndCompanionWebsite, setRndCompanionWebsite] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: "" });
  return (
    <ChatBotRadioInput
      question="Would you like us to design & develop a companion website for the
      app?"
      data={rndClientFormCompanionWebsiteData}
      value={rndCompanionWebsite}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setRndCompanionWebsite}
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_companion_website: rndCompanionWebsite,
          },
          trigger: "rnd_orientation",
        })
      }
    />
  );
};

const RndOrientation = ({ steps, triggerNextStep }) => {
  const [rndOrientation, setRndOrientation] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: "" });
  return (
    <ChatBotRadioInput
      question=" Is the app expected to work in both Portrait and Landscape
      orientation?"
      data={rndClientFormOrientationData}
      value={rndOrientation}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setRndOrientation}
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_orientation: rndOrientation,
          },
          trigger: "rnd_free_paid",
        })
      }
    />
  );
};

const RndFreePaid = ({ steps, triggerNextStep }) => {
  const [rndFreePaid, setRndFreePaid] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: "" });
  return (
    <ChatBotRadioInput
      question="Would you like to have the app listed as free or paid?"
      data={rndClientFormFree_PaidData}
      value={rndFreePaid}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setRndFreePaid}
      onClick={() =>
        triggerNextStep({
          value: {
            rnd_free_paid: rndFreePaid,
          },
          trigger: "rnd_user_web_services_api_format",
        })
      }
    />
  );
};

// RND End

// MAD Start

const MadDevicesToWorkOn = ({ steps, triggerNextStep }) => {
  const [madDevicesWorkOn, setMadDevicesWorkOn] = useState([]);
  const madDevicesData = madClientFormData.filter(
    (x) => x.refer === "mad_devices_to_work_on"
  )[0]?.children;
  return (
    <>
      <ChatBotCheckBox
        question="What device(s) is the application expected to work on?"
        setOptions={setMadDevicesWorkOn}
        option={madDevicesWorkOn}
        onClick={() =>
          triggerNextStep({
            value: {
              mad_devices_to_work_on: madDevicesWorkOn,
            },
            trigger: "mad_cf_layout",
          })
        }
        data={madDevicesData}
      />
    </>
  );
};

const MadCFLayout = ({ steps, triggerNextStep }) => {
  const [madCfLayout, setMadCfLayout] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const madCfLayoutData = madClientFormData.filter(
    (x) => x.refer === "mad_cf_layout"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="Do you want to have a separate layout for the tablet and phone?"
      data={madCfLayoutData}
      value={madCfLayout}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setMadCfLayout}
      onClick={() =>
        triggerNextStep({
          value: {
            mad_cf_layout: madCfLayout,
          },
          trigger: "mad_cf_orientation",
        })
      }
    />
  );
};

const MadCFOrientation = ({ steps, triggerNextStep }) => {
  const [madCfOrientation, setMadCfOrientation] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const madCfOrientationData = madClientFormData.filter(
    (x) => x.refer === "mad_cf_orientation"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="Do you want your app portrait or landscape or both?"
      data={madCfOrientationData}
      value={madCfOrientation}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setMadCfOrientation}
      onClick={() =>
        triggerNextStep({
          value: {
            mad_cf_orientation: madCfOrientation,
          },
          trigger: "mad_cf_app_icon",
        })
      }
    />
  );
};

const MadCFAppIcon = ({ steps, triggerNextStep }) => {
  const [madCfAppIcon, setMadCfAppIcon] = useState();
  const [isCurrentChecked, setIsCurrentChecked] = useState({ id: -1 });
  const madCfAppIconData = madClientFormData.filter(
    (x) => x.refer === "mad_cf_app_icon"
  )[0]?.children;
  return (
    <ChatBotRadioInput
      question="DDo you have an app icon or logo already? If yes, please attach in the attachments section"
      data={madCfAppIconData}
      value={madCfAppIcon}
      isCurrentChecked={isCurrentChecked}
      setIsCurrentChecked={setIsCurrentChecked}
      setData={setMadCfAppIcon}
      onClick={() =>
        triggerNextStep({
          value: {
            mad_cf_app_icon: madCfAppIcon,
          },
          trigger: "mad_cf_user_tone_of_your_site",
        })
      }
    />
  );
};

// MAD End

// MAM Start
const MAMCfServices = ({ steps, triggerNextStep }) => {
  const [mamServices, setMamServices] = useState([]);
  const mamData = mamClientFormData.filter(
    (x) => x.refer === "mam_cf_services"
  )[0]?.children;
  return (
    <>
      <ChatBotCheckBox
        question="We provide the following services as a part of the Mobile App Maintenance. Please choose"
        setOptions={setMamServices}
        option={mamServices}
        onClick={() =>
          triggerNextStep({
            value: {
              mam_cf_services: mamServices,
            },
            trigger: "q-submit",
          })
        }
        data={mamData}
      />
    </>
  );
};
// MAM End

// Final Submission To Backend
const FinalSubmission = ({ steps }) => {
  const dispatch = useDispatch();
  console.log(steps);
  useEffect(() => {
    submitToDB();
  })

  const submitToDB = () => {
    const result = {
      // Todo
    };
    const data = { ...result, final_submitted: true };
    dispatch(
      clientFormAction(workorder_id, data, "Submitted Successfully")
    );
  }
  return <div></div>;
};
// End of Final Submission

export const steps = [
  {
    id: "1",
    message:
      "Want to chat about your digital needs, I am here to help you find your way I am 'We-The Bot' Can I ask you a few questions?",
    trigger: "response",
  },
  {
    id: "response",
    options: [
      { value: "yes", label: "Yes", trigger: "yes" },
      { value: "no", label: "No", trigger: "no" },
    ],
  },
  {
    id: "yes",
    message: "Great! Super. May I have your first name?",
    trigger: "name",
  },
  {
    id: "no",
    message:
      "No problem. You can fill in the contact form and ThanWe will contact you. Else you can contact us on thanweconsultants@gmail.com",
    trigger: "thanks",
  },
  {
    id: "thanks",
    message: "Thanks for chatting with us!",
    trigger: "restart",
  },
  {
    id: "restart",
    options: [
      { value: "restart", label: "Click Here To Restart", trigger: "1" },
    ],
  },
  {
    id: "name",
    user: true,
    trigger: "knowyourdetails",
  },
  {
    id: "knowyourdetails",
    message:
      "Hey {previousValue}! We’re excited to help you out. Let us know your details so that we can follow up in case we get disconnected.",
    trigger: "email-trigger",
  },
  {
    id: "email-trigger",
    message: "Please enter your email id",
    trigger: "email",
  },
  {
    id: "email",
    user: true,
    validator: (value) => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return true;
      } else {
        return "Please enter a valid email.";
      }
    },
    trigger: "phone_number_trigger",
  },
  {
    id: "phone_number_trigger",
    message: "Please enter your mobile number",
    trigger: "phonenumber",
  },
  {
    id: "phonenumber",
    user: true,
    validator: (value) => {
      if (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)) {
        return true;
      } else {
        return "Please enter a valid phone number.";
      }
    },
    trigger: "company",
  },
  {
    id: "company",
    message: " Can you also tell your company name?",
    trigger: "company_name",
  },
  {
    id: "company_name",
    user: true,
    trigger: "looking_for",
  },

  {
    id: "looking_for",
    message: "Can you tell me what exactly you are looking for?",
    trigger: "looking_for_options",
  },
  {
    id: "looking_for_options",
    options: [
      {
        value: "Web App Development",
        label: "Web App Development",
        trigger: "wad",
      },
      {
        value: "Web App Support & Maintenance",
        label: "Web App Support & Maintenance",
        trigger: "wad",
      },
      {
        value: "Search Engine Optimization",
        label: "Search Engine Optimization",
        trigger: "wad",
      },
      {
        value: "React Native App Development",
        label: "React Native App Development",
        trigger: "wad",
      },
      {
        value: "Mobile App Design",
        label: "Mobile App Design",
        trigger: "wad",
      },
      {
        value: "Mobile App Maintenance",
        label: "Mobile App Maintenance",
        trigger: "wad",
      },
    ],
  },
  {
    id: "wad",
    component: <DetailsComponent />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "wad_yes_no",
    options: [
      {
        value: "yes",
        label: "Yes!",
        trigger: "wad_yes",
      },
      {
        value: "no",
        label: "No",
        trigger: "wad_no",
      },
    ],
  },
  {
    id: "wad_yes",
    message: ({ steps }) =>
      `Before we discuss further can you please let us know about your profession or role in ${steps.company_name.value}.`,
    trigger: "wad_yes_options",
  },
  {
    id: "wad_yes_options",
    options: [
      {
        value: "business owner",
        label: "Business Owner",
        trigger: "wad_business_owner",
      },
      {
        value: "consultant",
        label: "Consultant",
        trigger: "wad_business_owner",
      },
      {
        value: "software developer",
        label: "Software developer",
        trigger: "wad_business_owner",
      },
      {
        value: "If any other, type and press enter",
        label: "If any other, type and press enter",
        trigger: "type_wad_business_owner",
      },
    ],
  },
  {
    id: "type_wad_business_owner",
    user: true,
    trigger: "wad_business_owner",
  },

  //Start of Wad Business Owner
  {
    id: "wad_business_owner",
    message:
      "Got it. I will make a note of it. Can you please tell your major product / service offerings (solution offered to the end customer)?",
    trigger: "wad_offerings",
  },
  {
    id: "wad_offerings",
    user: true,
    trigger: "wad_target_market",
  },
  {
    id: "wad_target_market",
    message:
      "What is your target market (age, gender, ethnicity, geographical area, interests, etc)?",
    trigger: "wad_target_market_user_response",
  },
  {
    id: "wad_target_market_user_response",
    user: true,
    trigger: "wad_different_from_your_competitors",
  },
  {
    id: "wad_different_from_your_competitors",
    message:
      "What makes you different from your competitors (Unique selling point provided by your business)?",
    trigger: "wad_different_from_your_competitors_user_response",
  },
  {
    id: "wad_different_from_your_competitors_user_response",
    user: true,
    trigger: ({ steps }) =>
      `${triggerSpecificId(steps.looking_for_options.value)}`,
  },

  //1. Website App Development
  {
    id: "wad_your_requirement",
    message:
      "Thanks for providing the basic details. Now let us discuss on the requirement.",
    trigger: "wad_goals_options",
  },
  {
    id: "wad_goals_options",
    component: <GoalOptions />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "standard_pages",
    component: <StandardPagesOptions />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "functionality_required",
    message:
      "Shall we now proceed to talk about Functionality required in the website ",
    trigger: "continue_functionality_options",
  },
  {
    id: "continue_functionality_options",
    options: [
      {
        value: "yes",
        label: "Yes",
        trigger: "continue_functionality_options_yes",
      },
      {
        value: "no",
        label: "No",
        trigger: "wad_no",
      },
    ],
  },

  //Functionality Required
  {
    id: "continue_functionality_options_yes",
    component: <FunctionalitesOptions />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "functionality_required_thanks",
    message:
      "Thanks for providing details on functionality required. Shall we now proceed to talk about Web app design ?",
    trigger: "web_app_design_yes_no",
  },

  //Web app Design
  {
    id: "web_app_design_yes_no",
    options: [
      {
        value: "yes",
        label: "Yes",
        trigger: "continue_web_app_design",
      },
      {
        value: "no",
        label: "No",
        trigger: "wad_no",
      },
    ],
  },

  {
    id: "continue_web_app_design",
    message: "That's great, lets proceed on the design requirements",
    trigger: "design_tone_ques",
  },
  {
    id: "design_tone_ques",
    message: `Describe the tone of the site required (e.g. conservative / friendly / fun / sophisticated /minimalist / exciting, etc.)`,
    trigger: "design_tone",
  },
  {
    id: "design_tone",
    user: true,
    trigger: "design_colors_ques",
  },
  {
    id: "design_colors_ques",
    message: `Do you have any colours in your mind which we need to use for our website? add in comma separated (eg: black, blue etc...)`,
    trigger: "design_colors",
  },
  {
    id: "design_colors",
    user: true,
    trigger: "design_colors_avoided_ques",
  },
  {
    id: "design_colors_avoided_ques",
    message: `That’s some good selection of colours. Please do let us know if you think any colours are to be avoided. Type No incase there are colours need to be avoided.`,
    trigger: "design_colors_avoided",
  },
  {
    id: "design_colors_avoided",
    user: true,
    trigger: "inspiration_website_question",
  },
  {
    id: "inspiration_website_question",
    component: <InspirationWebsiteQuestion />,
    asMessage: true,
    trigger: "inspiration_websites",
  },
  {
    id: "inspiration_websites",
    user: true,
    trigger: "inspiration_website_yes_no",
  },
  {
    id: "inspiration_website_yes_no",
    component: <InspirationAnswerBasedQuestion />,
    asMessage: true,
    trigger: "additional_services_question",
  },
  {
    id: "additional_services_question",
    message: `With many new features getting added in the market everyday, we can help you with many of those. Please let us know if you are interested`,
    trigger: "additional_services_yes_no",
  },
  {
    id: "additional_services_yes_no",
    options: [
      {
        value: "yes",
        label: "Yes",
        trigger: "additional_services",
      },
      {
        value: "no",
        label: "No",
        trigger: "final_question",
      },
    ],
  },
  {
    id: "additional_services",
    component: <AdditionalServicesOptions />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "final_question",
    message: `And coming to the final question, do you have any domain or hosting service already availed. 

    If so click Yes and provide the details.
    
    Else click No.`,
    trigger: "domain_availability",
  },
  {
    id: "domain_availability",
    options: [
      {
        value: "yes",
        label: "Yes",
        trigger: "domain_availability_yes",
      },
      {
        value: "no",
        label: "No",
        trigger: "domain_availability_no",
      },
    ],
  },
  {
    id: "domain_availability_no",
    message: `No problem, we are here to provide you with all necessary details on domain and hosting.
    Just let us know what name best suits for your website.`,
    trigger: "best_suits_website_domain",
  },
  {
    id: "best_suits_website_domain",
    user: true,
    trigger: "anything_else_ques",
  },
  {
    id: "domain_availability_yes",
    message: `That makes things easy, can you please specify the domain registar.`,
    trigger: "domain_name",
  },
  {
    id: "domain_name",
    user: true,
    trigger: "anything_else_ques",
  },
  {
    id: "anything_else_ques",
    message:
      "That’s all the details we require. Is there anything else I can help you with today?",
    trigger: "anything_else_options",
  },
  {
    id: "anything_else_options",
    options: [
      {
        value: "yes",
        label: "Yes",
        trigger: "noted",
      },
      {
        value: "no",
        label: "No",
        trigger: "q-submit",
      },
    ],
  },
  //Need send the details to database by creating a new components and waitAction and triggerNextStep


  //Start Search Engine Optimization
  {
    id: "search_engine_optimization",
    message:
      "Thanks for providing the basic details. Now let us discuss on the requirement.",
    trigger: "seo_services",
  },
  {
    id: "seo_services",
    component: <SEOServicesOptions />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "seo_scale_operation",
    component: <SEOScaleOperations />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "seo_website_visitors",
    component: <SEOWebsiteVisitors />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "seo_monthly_budget",
    component: <SEOMonthlyBudget />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "seo_personal_individual",
    component: <SEOPersonalIndividual />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "seo_what_industry_operate",
    component: <SEOWhatIndustryDoYouOperate />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "seo_work_begin",
    component: <SEOWorkBegin />,
    asMessage: true,
    waitAction: true,
  },
  //End Search Engine Optimization

  // Start of Web App Support And Maintenance
  {
    id: "web_app_support_maintenance",
    message:
      "Thanks for providing the basic details. Now let us discuss on the requirement.",
    trigger: "wasm_user_about_your_app",
  },
  {
    id: "wasm_user_about_your_app",
    message:
      "Can you tell us a little bit about your web app? What does it do, and what is its purpose?",
    trigger: "wasm_about_your_app",
  },
  {
    id: "wasm_about_your_app",
    user: true,
    trigger: "wasm_user_general_support",
  },
  {
    id: "wasm_user_general_support",
    message:
      "Are you experiencing any specific issues with your web app, or are you looking for general maintenance and support?",
    trigger: "wasm_general_support",
  },
  {
    id: "wasm_general_support",
    user: true,
    trigger: "wasm_user_level_of_support",
  },
  {
    id: "wasm_user_level_of_support",
    message:
      "What level of support are you looking for? For example, do you need 24/7 support, or are regular business hours sufficient?",
    trigger: "wasm_level_of_support",
  },
  {
    id: "wasm_level_of_support",
    user: true,
    trigger: "wasm_user_level_of_support",
  },
  {
    id: "wasm_user_level_of_support",
    message:
      "What level of support are you looking for? For example, do you need 24/7 support, or are regular business hours sufficient?",
    trigger: "wasm_level_of_support",
  },
  {
    id: "wasm_level_of_support",
    user: true,
    trigger: "wasm_user_budget",
  },
  {
    id: "wasm_user_budget",
    message:
      "What is your budget for web app support and maintenance services?",
    trigger: "wasm_budget",
  },
  {
    id: "wasm_budget",
    user: true,
    trigger: "wasm_user_timeline_services",
  },
  {
    id: "wasm_user_timeline_services",
    message: "What is your timeline for getting started with these services?",
    trigger: "wasm_timeline_services",
  },
  {
    id: "wasm_timeline_services",
    user: true,
    trigger: "wasm_user_specific_features",
  },
  {
    id: "wasm_user_specific_features",
    message:
      "Are there any specific features or functionalities you are looking to add or improve in your web app?",
    trigger: "wasm_specific_features",
  },
  {
    id: "wasm_specific_features",
    user: true,
    trigger: "wasm_services",
  },
  {
    id: "wasm_services",
    component: <WasmServices />,
    asMessage: true,
    waitAction: true,
  },
  // End of Wasm

  // Start of React Native App Development
  {
    id: "react_native_app_development",
    message:
      "Thanks for providing the basic details. Now let us discuss on the requirement.",
    trigger: "rnd_design_works",
  },
  {
    id: "rnd_design_works",
    component: <RndDesignWorks />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_existing_app",
    component: <RndExistingApp />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_wireframes",
    component: <RndWireFrames />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_reference_app",
    component: <RndReferenceApp />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_existings_itunes_googleplay",
    component: <RndExitingItunesGplay />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_functionalities",
    component: <RndFunctionalitiesData />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_cms",
    component: <RndCms />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_multilingual",
    component: <RndMultilingual />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_companion_website",
    component: <RndCompanionWebsite />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_orientation",
    component: <RndOrientation />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "rnd_free_paid",
    component: <RndFreePaid />,
    asMessage: true,
    waitAction: true,
  },

  {
    id: "rnd_user_web_services_api_format",
    message: `If you’re providing us with web services, please indicate the API
      format which it’ll be provided. An API guide would be highly
      appreciated.`,
    trigger: "rnd_web_services_api_format",
  },
  {
    id: "rnd_web_services_api_format",
    user: true,
    trigger: "rnd_user_third_part_api_services",
  },
  {
    id: "rnd_user_third_part_api_services",
    message: `Would you like to integrate or use any third party API services for
        your application? ( For Eg: Social network authentication such as
        Facebook, Twitter, etc.)`,
    trigger: "rnd_third_part_api_services",
  },
  {
    id: "rnd_third_part_api_services",
    user: true,
    trigger: "q-submit",
  },

  // End of React Native App Development

  // Start of Mobile app Design
  {
    id: "mobile_app_design",
    message:
      "Thanks for providing the basic details. Now let us discuss on the requirement.",
    trigger: "mad_devices_to_work_on",
  },
  {
    id: "mad_devices_to_work_on",
    component: <MadDevicesToWorkOn />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "mad_cf_layout",
    component: <MadCFLayout />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "mad_cf_orientation",
    component: <MadCFOrientation />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "mad_cf_app_icon",
    component: <MadCFAppIcon />,
    asMessage: true,
    waitAction: true,
  },
  {
    id: "mad_cf_user_tone_of_your_site",
    message:
      "What three words should describe the tone of your site? (e.g. conservative / friendly / fun / sophisticated /minimalist / exciting, etc.)",
    trigger: "mad_cf_tone_of_your_site",
  },
  {
    id: "mad_cf_tone_of_your_site",
    user: true,
    trigger: "mad_cf_user_theme_of_your_site",
  },

  {
    id: "mad_cf_user_theme_of_your_site",
    message:
      "Do you have any opinions specifically for color, theme and style?",
    trigger: "mad_cf_theme_of_your_site",
  },
  {
    id: "mad_cf_theme_of_your_site",
    user: true,
    trigger: "mad_cf_user_colors_avoided",
  },

  {
    id: "mad_cf_user_colors_avoided",
    message: "Are there any colors that should be avoided?",
    trigger: "mad_cf_colors_avoided",
  },
  {
    id: "mad_cf_colors_avoided",
    user: true,
    trigger: "mad_cf_user_layout_designs",
  },

  {
    id: "mad_cf_user_layout_designs",
    message:
      "Do you have any UI/UX layout designs, prototypes or documentation?",
    trigger: "mad_cf_layout_designs",
  },
  {
    id: "mad_cf_layout_designs",
    user: true,
    trigger: "mad_cf_user_inspiration_websites",
  },

  {
    id: "mad_cf_user_inspiration_websites",
    message:
      "Please list two websites that you like and would like to use as inspiration for your own. Describe what you like about them.",
    trigger: "mad_cf_inspiration_websites",
  },
  {
    id: "mad_cf_inspiration_websites",
    user: true,
    trigger: "q-submit",
  },

  // End of Mobile App Design

  // Start of Mobile App Maintenance
  {
    id: "mobile_app_maintenance",
    message:
      "Thanks for providing the basic details. Now let us discuss on the requirement.",
    trigger: "mam_user_cf_about_your_mobileapp",
  },

  {
    id: "mam_user_cf_about_your_mobileapp",
    message:
      "Can you tell us a little bit about your mobile app? What does it do, and what is its purpose?",
    trigger: "mam_cf_about_your_mobileapp",
  },
  {
    id: "mam_cf_about_your_mobileapp",
    user: true,
    trigger: "mam_cf_user_issues_mobileapp",
  },

  {
    id: "mam_cf_user_issues_mobileapp",
    message:
      "Are you experiencing any specific issues with your mobile app, or are you looking for general maintenance and support?",
    trigger: "mam_cf_issues_mobileapp",
  },
  {
    id: "mam_cf_issues_mobileapp",
    user: true,
    trigger: "mam_cf_user_support_mobileapp",
  },

  {
    id: "mam_cf_user_support_mobileapp",
    message:
      "What level of support are you looking for? For example, do you need 24/7 support, or are regular business hours sufficient?",
    trigger: "mam_cf_support_mobileapp",
  },
  {
    id: "mam_cf_support_mobileapp",
    user: true,
    trigger: "mam_cf_user_budget_mobileapp",
  },

  {
    id: "mam_cf_user_budget_mobileapp",
    message:
      "What is your budget for mobile app support and maintenance services?",
    trigger: "mam_cf_budget_mobileapp",
  },
  {
    id: "mam_cf_budget_mobileapp",
    user: true,
    trigger: "mam_cf_user_timeline_mobileapp",
  },

  {
    id: "mam_cf_user_timeline_mobileapp",
    message: "What is your timeline for getting started with these services?",
    trigger: "mam_cf_timeline_mobileapp",
  },
  {
    id: "mam_cf_timeline_mobileapp",
    user: true,
    trigger: "mam_cf_user_specific_features_mobileapp",
  },

  {
    id: "mam_cf_user_specific_features_mobileapp",
    message: "What is your timeline for getting started with these services?",
    trigger: "mam_cf_specific_features_mobileapp",
  },
  {
    id: "mam_cf_specific_features_mobileapp",
    user: true,
    trigger: "mam_cf_services",
  },

  {
    id: "mam_cf_services",
    component: <MAMCfServices />,
    asMessage: true,
    waitAction: true,
  },

  // End of Mobile App Maintenance

  // Final Submmision
  {
    id: "q-submit",
    message: "Do you wish to submit?",
    trigger: "submit",
  },
  {
    id: "submit",
    options: [
      { value: "y", label: "Yes", trigger: "end-message" },
      { value: "n", label: "No", trigger: "no-submit" },
    ],
  },
  {
    id: "no-submit",
    message: "Your information was not submitted.",
    end: true,
  },
  {
    id: "end-message",
    component: <FinalSubmission />,
    asMessage: true,
    end: true,
  },
  {
    id: "anything_else_no",
    message: "Awesome! Have a great rest of the day!",
    trigger: "restart",
  },
  // End of final submission

  //End of the Chat and restart
  {
    id: "wad_no",
    message: `No problem. Thank you for providing your details, we will contact you as soon as possible to discuss further
      Just know, I am are here if you need me.
      Thanks for chatting with us!`,
    trigger: "restart",
  },
  {
    id: "noted",
    message: "Thanks for chatting with us!",
    end: true,
  },
];
