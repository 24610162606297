import * as clientFormConstants from "../Constants/ClientFormConstants";

export const addClientFormReducer = (state = {}, action) => {
  switch (action.type) {
    case clientFormConstants.CLIENT_FORM_REQUEST:
      return { isLoading: true };
    case clientFormConstants.CLIENT_FORM_SUCCESS:
      return { isLoading: false, isSuccess: true };
    case clientFormConstants.CLIENT_FORM_FAIL:
      return { isLoading: false, isError: action.payload };
    default:
      return state;
  }
};

export const getClientFormDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case clientFormConstants.CLIENT_FORM_DETAILS_REQUEST:
      return { isLoading: true };
    case clientFormConstants.CLIENT_FORM_DETAILS_SUCCESS:
      return { isLoading: false, clientFormData: action.payload };
    case clientFormConstants.CLIENT_FORM_DETAILS_FAIL:
      return { isLoading: false, isError: action.payload };
    default:
      return state;
  }
};

export const getFullClientFormDataReducer = (state = {}, action) => {
  switch (action.type) {
    case clientFormConstants.FULL_CLIENT_FORM_DATA_REQUEST:
      return { isLoading: true };
    case clientFormConstants.FULL_CLIENT_FORM_DATA_SUCCESS:
      return { isLoading: false, FullclientFormData: action.payload };
    case clientFormConstants.FULL_CLIENT_FORM_DATA_FAIL:
      return { isLoading: false, isError: action.payload };
    default:
      return state;
  }
};

export const getFullClientContactFormDataReducer = (state = {FullClientContactFormData: []}, action) => {
  switch (action.type) {
    case clientFormConstants.FULL_CLIENT_CONTACT_COLLECTION_REQUEST:
      return { isLoading: true };
    case clientFormConstants.FULL_CLIENT_CONTACT_COLLECTION_SUCCESS:
      return { isLoading: false, FullClientContactFormData: action.payload };
    case clientFormConstants.FULL_CLIENT_CONTACT_COLLECTION_FAIL:
      return { isLoading: false, isError: action.payload };
    default:
      return state;
  }
};
