import React from "react";

function ChatBotRadioInput({
  question,
  data,
  value,
  isCurrentChecked,
  setIsCurrentChecked,
  setData,
  onClick,
  inputBox = null
}) {
  return (
    <div className="flex flex-col">
      <p>{question}</p>
      {data.map((item, index) => {
        return (
          <div key={item.name} className="flex flex-row mb-2">
            <input
              onChange={(e) => {
                // add to list
                if (e.target.checked) {
                  setIsCurrentChecked(item.id);
                  setData(item.label);                 
                }
              }}
              name={item.name}
              value={value}
              className="mx-2"
              type="radio"
              checked={isCurrentChecked == item.id}
            />
            <p>{item.label}</p>
          </div>
        );
      })}
      <div className="mb-2">
      {inputBox && inputBox}
      </div>
      <div className="flex flex-col gap-2">
        <button
          aria-label="Next"
          className={"bg-sand px-2 py-2 rounded-md"}
          type="submit"
          onClick={onClick}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default ChatBotRadioInput;
