import {
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  collection,
} from "@firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

import { db, functions, timeStamp } from "../../Config/firebase";
import {
  acknowledgementEmailFormat,
  clientContactDetailsEmailFormat,
  finalSubmissionEmailFormat,
  removeHtmlTags,
} from "../../Helpers/twcHelpers";
import * as clientFormConstants from "../Constants/ClientFormConstants";

export const clientFormAction = (id, data, successMessage) => async (
  dispatch
) => {
  const sendEmailFirebaseFunction = httpsCallable(
    functions,
    "sendEmailHandler"
  );
  const link = `https://thanwe-website.web.app/thanweconsultants/clientRequirementForm/${id}`;
  const finalSubmissionBodyHtml = finalSubmissionEmailFormat(data.name, link);

  dispatch({ type: clientFormConstants.CLIENT_FORM_REQUEST });
  const docRef = doc(db, "tbl_client_form", id);

  setDoc(docRef, { ...data, updatedAt: serverTimestamp() }, { merge: true })
    .then(() => {
      dispatch({ type: clientFormConstants.CLIENT_FORM_SUCCESS });
      if (data.final_submitted) {
        sendEmailFirebaseFunction({
          from: "thanweconsultants@gmail.com",
          to: data.email,
          cc: ["thanweconsultants@gmail.com"],
          subject: `Final Submission - ${id}`,
          html: finalSubmissionBodyHtml,
        })
          .then((res) => {
            toast.success("Your details are submitted successfully.");
          })
          .catch((error) => {
            toast.error(error.data.message);
          });
      } else {
        toast.success(successMessage);
      }
    })
    .catch((err) => {
      alert(err.message);
      dispatch({
        type: clientFormConstants.CLIENT_FORM_FAIL,
        payload: err.message,
      });
    });
};

export const getClientFormDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: clientFormConstants.CLIENT_FORM_DETAILS_REQUEST });
    const docRef = doc(db, "tbl_client_form", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      onSnapshot(docRef, async (document) => {
        const customer_id = document.data()?.customer_id;
        const docContactRef = doc(db, "tbl_contact_form", customer_id);

        const contactFormData = await getDoc(docContactRef);

        dispatch({
          type: clientFormConstants.CLIENT_FORM_DETAILS_SUCCESS,
          payload: {
            ...document.data(),
            ...contactFormData.data(),
            id: document.id,
          },
        });
      });
    } else {
      toast.error("No records found!");
    }
  } catch (error) {
    dispatch({
      type: clientFormConstants.CLIENT_FORM_DETAILS_FAIL,
      payload: error.message,
    });
  }
};

export const addCommentsToClientForm = (
  message,
  id,
  email,
  time,
  setIsLoading
) => async (dispatch) => {
  try {
    // get comments if any to the particular id
    setIsLoading(true);
    const comments = [
      {
        message: message,
        email: email,
        createdAt: Timestamp.fromDate(new Date()),
      },
    ];

    const ref = doc(db, "tbl_client_form", id);
    const existingDoc = await getDoc(ref);
    const existingComments = existingDoc.data()?.comments || [];

    console.log("exis", [...existingComments, ...comments]);
    const resultComments = [...existingComments, ...comments];

    setDoc(
      ref,
      {
        comments: resultComments,
        updatedAt: timeStamp,
      },
      { merge: true }
    )
      .then(() => {
        toast.success("Added comments");
      })
      .catch((e) => console.log("error in 109", e.message));
    setIsLoading(false);
  } catch (error) {
    toast.error(error);
    setIsLoading(false);
  }
};

export const getFullClientFormDataDetails = (workorder_id) => async (
  dispatch
) => {
  try {
    dispatch({ type: clientFormConstants.FULL_CLIENT_FORM_DATA_REQUEST });
    const q = doc(db, "tbl_client_form", workorder_id);
    onSnapshot(q, async (document) => {
      const docContactRef = doc(
        db,
        "tbl_contact_form",
        document.data().customer_id
      );
      const arrComments = [];
      document.data()?.comments &&
        document.data()?.comments?.length > 0 &&
        document.data()?.comments.map((item) => {
          return arrComments.push({
            ...item,
            createdAt: item.createdAt.toDate(),
          });
        });
      const contactFormData = await getDoc(docContactRef);
      dispatch({
        type: clientFormConstants.FULL_CLIENT_FORM_DATA_SUCCESS,
        payload: {
          ...document.data(),
          ...contactFormData.data(),
          comments: arrComments,
          id: document.id,
          createdAt: document.data().createdAt.toDate(),
          updatedAt: document.data().updatedAt.toDate(),
        },
      });
    });
  } catch (error) {
    toast.error(error);
  }
};

export const contactScreenAction = (
  data,
  setLoading,
  id,
  workorder_id,
  link
) => async (dispatch) => {
  try {
    let contactData = {
      name: data.name,
      email: data.email,
      phone_number: data.phonenumber,
      customer_id: id.toString(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      updatedBy: data.email,
    };

    let clientFormData = {
      message: removeHtmlTags(data.message),
      workorder_id: workorder_id.toString(),
      company: data.company ? data.company : "",
      technologyOptions: data?.techOptions ? data?.techOptions : [],
      requirements: data?.requirements ? data?.requirements : [],
      final_submitted: false,
      status: "Prospect",
      customer_id: id.toString(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      updatedBy: data.email,
    };

    const getDataFromStatus = await getDoc(
      doc(db, "tbl_based_on_status", "Prospect")
    );

    const _statusDataToMerge = getDataFromStatus.data().cards;
    const cardData = [
      { id: workorder_id.toString(), workorder_id: workorder_id.toString() },
    ];
    let statusData = {
      cards: [...cardData, ..._statusDataToMerge],
      title: "Prospect",
      createdAt: serverTimestamp(),
    };

    const docRef = doc(db, "tbl_contact_form", id.toString());
    const clientDocRef = doc(db, "tbl_client_form", workorder_id.toString());
    const tableBasedOnStatusRef = doc(db, "tbl_based_on_status", "Prospect");

    setDoc(docRef, contactData)
      .then(() => {
        sendEmail({ ...contactData, ...clientFormData }, setLoading, link);
      })
      .catch((err) => {
        alert(err.message);
        setLoading(false);
      });
    setDoc(clientDocRef, clientFormData)
      .then(() => {})
      .catch((err) => setLoading(false));
    setDoc(tableBasedOnStatusRef, statusData, { merge: true })
      .then(() => {})
      .catch((err) => setLoading(false));
  } catch (error) {}
};

const sendEmail = (contactData, setLoading, link) => {
  const sendEmailFirebaseFunction = httpsCallable(
    functions,
    "sendEmailHandler"
  );
  const { name, phone_number, message, email, workorder_id } = contactData;

  const AcknowledgeEmailHtml = acknowledgementEmailFormat(name, link);
  const SenderMailHtml = clientContactDetailsEmailFormat(
    name,
    message,
    email,
    phone_number,
    link,
    workorder_id
  );

  const acknowledgeEmailData = {
    from: "thanweconsultants@gmail.com",
    to: email,
    subject: "ThanWe Consultants: Acknowledgement",
    html: AcknowledgeEmailHtml,
  };

  const senderEmailData = {
    from: email,
    to: "thanweconsultants@gmail.com",
    subject: "Subject: Request for Website/Mobile projects",
    html: SenderMailHtml,
  };

  sendEmailFirebaseFunction(senderEmailData)
    .then((res) => {
      setLoading(false);
    })
    .catch((error) => {
      toast.error(error.data.message);
      setLoading(false);
    });
  sendEmailFirebaseFunction(acknowledgeEmailData)
    .then((res) => {
      toast.success("Your message has been sent successfully");
      setLoading(false);
    })
    .catch((error) => {
      toast.error(error.data.message);
      setLoading(false);
    });
};

export const getFullClientAndContactFormCollectionAction = (setFilteredData) => async (
  dispatch
) => {
  try {
    dispatch({
      type: clientFormConstants.FULL_CLIENT_CONTACT_COLLECTION_REQUEST,
    });
    const docRef = collection(db, "tbl_client_form");
    onSnapshot(docRef, (snapshot) => {
      const fullData = [];
      snapshot.docs.forEach(async (document) => {
        const customer_id = document.data()?.customer_id;
        const docContactRef = doc(db, "tbl_contact_form", customer_id);

        const contactFormData = await getDoc(docContactRef);
        fullData.push({
          ...document.data(),
          ...contactFormData.data(),
          id: document.id,
          createdAt: document.data().createdAt?.toDate(),
          updatedAt: document.data()?.updatedAt?.toDate(),
        });
        setFilteredData(fullData)
        dispatch({
          type: clientFormConstants.FULL_CLIENT_CONTACT_COLLECTION_SUCCESS,
          payload: fullData,
        });
      });
    });
    
  } catch (error) {
    toast.error(error);
  }
};
