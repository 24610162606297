export const CLIENT_FORM_REQUEST = "CLIENT_FORM_REQUEST";
export const CLIENT_FORM_SUCCESS = "CLIENT_FORM_SUCCESS";
export const CLIENT_FORM_FAIL = "CLIENT_FORM_FAIL";

export const CLIENT_FORM_DETAILS_REQUEST = "CLIENT_FORM_DETAILS_REQUEST";
export const CLIENT_FORM_DETAILS_SUCCESS = "CLIENT_FORM_DETAILS_SUCCESS";
export const CLIENT_FORM_DETAILS_FAIL = "CLIENT_FORM_DETAILS_FAIL";

export const FULL_CLIENT_FORM_DATA_REQUEST = "FULL_CLIENT_FORM_DATA_REQUEST";
export const FULL_CLIENT_FORM_DATA_SUCCESS = "FULL_CLIENT_FORM_DATA_SUCCESS";
export const FULL_CLIENT_FORM_DATA_FAIL = "FULL_CLIENT_FORM_DATA_FAIL";

export const FULL_CLIENT_CONTACT_COLLECTION_REQUEST = "FULL_CLIENT_CONTACT_COLLECTION_REQUEST";
export const FULL_CLIENT_CONTACT_COLLECTION_SUCCESS = "FULL_CLIENT_CONTACT_COLLECTION_SUCCESS";
export const FULL_CLIENT_CONTACT_COLLECTION_FAIL = "FULL_CLIENT_CONTACT_COLLECTION_FAIL";