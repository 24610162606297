import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

//public protection
function ProtectedRouter() {
  const { thanWeUserInfo } = useSelector((state) => state.userLogin);

  return thanWeUserInfo?.stsTokenManager?.accessToken ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
}

// //admin router protection
// function AdminProtectedRouter() {
//   const { userInfo } = useSelector((state) => state.userLogin);
//   return userInfo?.token ? (
//     userInfo?.isAdmin ? (
//       <Outlet />
//     ) : (
//       <Navigate to="/*" />
//     )
//   ) : (
//     <Navigate to="/login" />
//   );
// }

export { ProtectedRouter };
