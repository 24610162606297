import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import { steps } from "../../Data/ChatBotSteps";
import {  bot2Image, bot3Image } from "../../utils/imageConstants";

function CustomChatBot() {
  const theme = {
    background: "#f5f8fb",
    fontFamily: "Helvetica Neue",
    headerBgColor: "#5BDBD3",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    botBubbleColor: "#fff",
    botFontColor: "#000",
    userBubbleColor: "orange",
    userFontColor: "#fff",
  };

  const handleEnd = ({ steps, values }) => {
    // console.log(steps);
    // console.log(values);
    //console.log(`Chat handleEnd callback! Number: ${JSON.stringify(steps)}`);
  };
  const config = {
    width: "400px",
    height: "500px",
  };
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        handleEnd={handleEnd}
        headerTitle="We-The Bot"
        style={{ fontFamily: "jost" }}
        floating={true}
        enablesmoothScroll={true}
        recognitionEnable={true}
        steps={steps}
        botAvatar={bot3Image}
        {...config}
      />
    </ThemeProvider>
  );
}

export default CustomChatBot;
